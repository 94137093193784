import { Dropdown, PhoneNumber, TextInput, UnknownToggle } from "../../components";
import { getOptionData } from "../../utilities/helpers";

export const reporterEmailFields = (formData, reactFormProps, errors) => (
    <UnknownToggle
        id={"reporterEmailToggle"}
        defaultToggled={formData["reporterEmail"] === "unknown"}
    >
        <TextInput
            {...reactFormProps}
            name="reporterEmail"
            label="Email Address *"
            placeholderText={"email@email.com"}
            error={errors.reporterEmail?.message}
            size={"extra-large"}
            defaultValue={formData["reporterEmail"]}
        />
    </UnknownToggle>
);

export const reporterPhoneFields = (formData, reactFormProps, errors) => (
    <UnknownToggle
        id={"reporterPhoneToggle"}
        defaultToggled={formData["reporterPhone"] === "unknown"}
    >
        <PhoneNumber
            {...reactFormProps}
            name="reporterPhone"
            label="Phone Number *"
            placeholderText={"111-222-3334"}
            error={errors.reporterPhone?.message}
            size={"extra-large"}
            defaultValue={formData["reporterPhone"]}
        />
    </UnknownToggle>
)

export const reporterAddressFields = (formData, reactFormProps, errors, reporterCountryPlaceholder) => (
    <div className="extra-large">
        <TextInput
            {...reactFormProps}
            name="reporterAddressLine1"
            label="Address Line 1 *"
            placeholderText={"Enter Address"}
            error={errors.reporterAddressLine1?.message}
            size={"extra-extra-large"}
            defaultValue={formData["reporterAddressLine1"]}
        />
        <TextInput
            {...reactFormProps}
            name="reporterAddressLine2"
            label="Address Line 2"
            placeholderText={"Enter Address"}
            error={errors.reporterAddressLine2?.message}
            size={"extra-extra-large"}
            defaultValue={formData["reporterAddressLine2"]}
        />
        <div className="row-horizontal">
            <TextInput
                {...reactFormProps}
                name="reporterCity"
                label="City *"
                placeholderText={"Enter City"}
                error={errors.reporterCity?.message}
                size={"extra-large"}
                defaultValue={formData["reporterCity"]}
            />
            <TextInput
                {...reactFormProps}
                name="reporterState"
                label="State *"
                placeholderText={"Enter State"}
                error={errors.reporterState?.message}
                size={"extra-large"}
                defaultValue={formData["reporterState"]}
            />
        </div>
        <div className="row-horizontal">
            <TextInput
                {...reactFormProps}
                name="reporterZip"
                label="Zip/Postal Code *"
                placeholderText={"Enter Zip Code"}
                error={errors.reporterZip?.message}
                size={"extra-large"}
                defaultValue={formData["reporterZip"]}
            />
            <Dropdown
                {...reactFormProps}
                name="reporterCountry"
                label="Country *"
                items={window.dropdownOptions?.country_options}
                placeholder={reporterCountryPlaceholder}
                size={"extra-large"}
                error={errors.reporterCountry?.message}
                defaultItem={getOptionData(
                    window.dropdownOptions?.country_options,
                    formData["reporterCountry"]
                )}
            />
        </div>
    </div>
)