import { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import "./App.scss";
import { Header, Progress, PreventNavigate } from "./components";
import {
  Landing,
  Issue,
  Reporter,
  Patient,
  Product,
  QualityAssurance,
  AdverseEvent,
  Review,
  Logout,
  Error,
} from "./pages";
import { getAuth } from "./utilities/auth";
import useWindowSize, {
  deviceWidthBreakpoints
} from "./hooks/useWindowSize";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

function App() {
  const { width } = useWindowSize();
  const loginData = window.shouldSignInPopup && getAuth();

  const initialState = {
    user: `${loginData?.fn} ${loginData?.sn}`.replace(/\w\S*/g, (w) =>
      w.replace(/^\w/, (c) => c.toUpperCase())
    ),
    firstName: loginData?.fn,
    lastName: loginData?.sn,
    email: loginData?.email,
    isAE: false,
    isPQ: false,
  };

  const [formData, setFormData] = useState(initialState);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const clearState = () => {
    setFormData({ ...initialState });
    setCurrentIndex(-1);
  };

  const goNextSection: Function = (sectionData, nextProgressIndex) => {
    const data = { ...formData, ...sectionData };
    setFormData(data);
    setCurrentIndex(nextProgressIndex);
  };

  // This will redirect the page if accessed directly by url back to the home page
  let history = useHistory();
  if (window.location.pathname !== "/landing" && currentIndex === -1) {
    history.push("/");
  }

  // Used for testing, we can remove it later
  useEffect(() => {
    console.log("formData", formData);
  }, [formData]);

  const [shouldPreventNavigate, setShouldPreventNavigate] = useState(true);

  return (
    <div className={`app ${width <= MOBILE_MAX ? "app-mobile" : ""}`}>
      {shouldPreventNavigate && <PreventNavigate />}
      <Switch>
        <Route
          exact
          path={"/logout"}
          render={() => (
            <Logout setShouldPreventNavigate={setShouldPreventNavigate} />
          )}
        />
        <Route exact path={"/error"} render={() => <Error />} />
        <Route
          path={"/"}
          render={() => (
            <div>
              <Header formData={formData} />
              <div className={`main ${width <= MOBILE_MAX ? "main-mobile" : ""} ${width <= TABLET_MAX ? "main-tablet" : ""}`}>
                <div className="bx--grid grid">
                  <div className="bx--row row">
                    <div className="bx--col-lg-3 col">
                      <Progress currentIndex={currentIndex}></Progress>
                    </div>
                    <div className="bx--col-lg-9 col">
                      <div id="form-container">
                        <Switch>
                          <Route
                            exact
                            path={"/issue"}
                            render={() => (
                              <Issue
                                goNextSection={goNextSection}
                                formData={formData}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={"/reporter"}
                            render={() => (
                              <Reporter
                                goNextSection={goNextSection}
                                formData={formData}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={"/patient"}
                            render={() => (
                              <Patient
                                goNextSection={goNextSection}
                                formData={formData}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={"/product"}
                            render={() => (
                              <Product
                                goNextSection={goNextSection}
                                formData={formData}
                                isAE={formData.isAE}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={"/adverseEvent"}
                            render={() => (
                              <AdverseEvent
                                goNextSection={goNextSection}
                                formData={formData}
                                isAE={formData.isAE}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={"/qualityassurance"}
                            render={() => (
                              <QualityAssurance
                                goNextSection={goNextSection}
                                formData={formData}
                                isPQ={formData.isPQ}
                              />
                            )}
                          />
                          <Route
                            exact
                            path={"/review"}
                            render={() => (
                              <Review
                                goNextSection={goNextSection}
                                formData={formData}
                                clearState={clearState}
                              />
                            )}
                          />
                          <Route
                            path={"/"}
                            render={() => (
                              <Landing
                                goNextSection={goNextSection}
                                formData={formData}
                              />
                            )}
                          />
                        </Switch>
                      </div>
                    </div>
                  </div>
                  {/* {process.env.NODE_ENV === "development" && (
                    <ul id="data-overlay">
                      {Object.keys(formData).map((field, index) => (
                        <li key={index}>
                          {field} : {JSON.stringify(formData[field])}
                        </li>
                      ))}
                    </ul>
                  )} */}
                </div>
              </div>
            </div>
          )}
        ></Route>
      </Switch>
    </div>
  );
}

export default App;
