import { FC, useState, useEffect } from "react";
import "./styles.scss";
import { DatePicker as AntDatePicker, Select, Space } from "antd";
import moment from "moment";
import { QuestionTooltip } from "../../../../components";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../../../hooks/useWindowSize";

const { MOBILE_MAX } = deviceWidthBreakpoints;

interface DatePickerProps {
  label: string;
  name: string;
  control: any;
  Controller: any;
  setValue?: Function;
  watch?: Function;
  error?: string;
  size?: string;
  placeholderText?: string;
  helperText?: string;
  disabled?: boolean;
  toggleValue?: boolean;
  disableFutureDates?: boolean;
  resetField?: any;
  defaultValue?: object | null | string;
  defaultType?: string;
}

const DatePicker: FC<DatePickerProps> = (props) => {
  const {
    name,
    label,
    disabled,
    control,
    Controller,
    setValue = () => {},
    toggleValue,
    placeholderText,
    disableFutureDates = true,
    size = "extra-large",
    defaultValue,
    defaultType,
  } = props;

  const { width } = useWindowSize();

  useEffect(() => {
    if (toggleValue) {
      // this setValue is what triggers the watch above
      setValue(name, "unknown", { shouldValidate: true });
    } else if (!toggleValue) {
      setValue(name, "");
    }
  }, [toggleValue]);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue, {
        shouldValidate: true,
      });
    }
  }, []);

  const renderDateTooltip = (
    <QuestionTooltip
      message={"If full date is unknown select month or year from dropdown."}
    />
  );

  const { Option } = Select;

  const dateFormat = (type) => {
    if (type === "date") {
      return [
        // Any of these formats will be able to be typed into the date picker
        "DD MMM YYYY", // <--- This is what the format will look like to the user
        "DD MM YYYY",
        "DD/MMM/YYYY",
        "DD-MMM-YYYY",
        "DD/MM/YYYY",
        "DD/MM/YY",
        "DD-MM-YYYY",
        "DD-MM-YY",
        "YYYY-MM-DD",
        "YYYY/MM/DD",
        "YYYY MM DD",
      ];
    }
    if (type === "month") {
      return [
        "MMM YYYY",
        "MM YYYY",
        "MMM/YYYY",
        "MMM-YYYY",
        "MM/YYYY",
        "MM/YY",
        "MM-YYYY",
        "MM-YY",
        "YYYY-MM",
        "YYYY/MM",
        "YYYY MM",
      ];
    }
    if (type === "year") {
      return ["YYYY", "YY"];
    }
  };

  const [type, setType] = useState(defaultType || "date");

  useEffect(() => {
    setValue(`${name}Type`, type);
  }, [type]);

  function PickerWithType({ type }) {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <AntDatePicker
              id={name}
              className="date-picker"
              size="large"
              picker={type}
              placement={width <= MOBILE_MAX ? "topLeft" : undefined}
              inputReadOnly={width <= MOBILE_MAX}
              value={
                value === "unknown" || value?._i === "unknown" ? null : value
              }
              onChange={onChange}
              placeholder={
                toggleValue || defaultValue === "unknown"
                  ? "unknown"
                  : placeholderText
              }
              onBlur={onBlur}
              disabled={disabled || toggleValue}
              format={dateFormat(type)}
              disabledDate={(current) => {
                if (!disableFutureDates) return false;
                else return current > moment().endOf("day").add(1, "d");
              }}
            />
          );
        }}
      />
    );
  }

  return (
    <div className={size}>
      <div className="input-label">
        {label}
        {renderDateTooltip}
      </div>
      <Space>
        <Select
          className="date-picker-dropdown"
          dropdownClassName="select-dropdown"
          value={type}
          onChange={setType}
          size="large"
          disabled={disabled || toggleValue}
        >
          <Option value="date">Date</Option>
          <Option value="month">Month</Option>
          <Option value="year">Year</Option>
        </Select>
        <PickerWithType type={type} />
      </Space>
    </div>
  );
};

export default DatePicker;
