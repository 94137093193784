import set from "lodash/set";
import moment from "moment";

const sharp_lot_number_mapping = {
  "Packaging/product was discarded": {
    ae: "packaging_discarded",
    pq: "Discarded Package/Product with Lot Number"
  },
  "Lot Number not tracked by patient/reporter": {
    ae: "physician_inaccessible",
    pq: "Physician office does track Lot Number"
  },
  "Lot number not tracked by pharmacy": {
    ae: "physician_inaccessible",
    pq: "Pharmacy does not track Lot Number"
  },
  "Patient/reporter declined to provide Lot Number": {
    ae: "declined_to_provide",
    pq: "Primary Reporter declined to provide Lot Number"
  },
  "Patient/reporter declined to be contacted": {
    ae: "declined_contact",
    pq: "Primary Reporter declined further contact"
  },
  Other: {
    ae: "other",
    pq: "Other"
  }
};

// formats form data to exclude non-case properties
// and performs conditional stuff based on AE/PQ for
// certain fields
// -- returns formatted case object
export function formatCase(formData) {
  const caseObj = {};

  const getBatchNumberUnavailableMapping = (subcase) => {
    return sharp_lot_number_mapping[formData["batchNumberUnavailable"]]
      ? sharp_lot_number_mapping[formData["batchNumberUnavailable"]][subcase]
      : undefined;
  };

  const setTrilogyStatepath = (statepath, value) => {
    if (value && value !== "unknown" && value !== "Select...") {
      set(caseObj, statepath, value);
    }
  };
  let description = "";

  function convertDate(fieldName) {
    const date = formData[fieldName];
    const type = formData[`${fieldName}Type`];
    if (date && date !== "unknown") {
      if (type === "month") {
        return moment(date).format("YYYY-MM");
      } else if (type === "year") {
        return moment(date).format("YYYY");
      } else return moment(date).format("YYYY-MM-DD");
    } else return null;
  }

  function convertDateForDescription(fieldName) {
    const date = formData[fieldName];
    const type = formData[`${fieldName}Type`];
    if (date && date !== "unknown") {
      if (type === "month") {
        return moment(date).format("YYYY-MMM");
      } else if (type === "year") {
        return moment(date).format("YYYY");
      } else return moment(date).format("YYYY-MMM-DD");
    } else return "unknown";
  }

  // issue type
  if (formData["isAE"]) {
    setTrilogyStatepath("summary.narrative.categories.adverse_event", true);
    setTrilogyStatepath(
      "summary.affiliate_awareness_date",
      convertDate("awarenessDate")
    );
  }
  if (formData["isPQ"]) {
    setTrilogyStatepath("summary.narrative.categories.product_quality", true);
    setTrilogyStatepath(
      "summary.productQuality.affiliate_awareness_date",
      convertDate("awarenessDate")
    );
  }

  // reporter
  setTrilogyStatepath("contacts.contact[0].reporter.primary", true);
  if (formData["isPQ"]) {
    setTrilogyStatepath("contacts.contact[0].pq.initial_reporter", true);
  }
  switch (formData["reportedByPatient"]) {
    case "I’m the patient":
      setTrilogyStatepath("contacts.contact[0].type", "patient");
      // set 2nd contact to be employee entering details into ShARP
      setTrilogyStatepath("patient.patient.firstName", formData["firstName"]);
      setTrilogyStatepath("patient.patient.lastName", formData["lastName"]);
      setTrilogyStatepath(
        "patient.patient.contact_info.email.address",
        formData["email"]
      );
      break;
    case "Yes":
      setTrilogyStatepath(
        "patient.patient.firstName",
        formData["patientFirstName"]
      );
      setTrilogyStatepath(
        "patient.patient.lastName",
        formData["patientLastName"]
      );
      setTrilogyStatepath("contacts.contact[0].type", "patient");
      setTrilogyStatepath(
        "contacts.contact[0].aer.occupation",
        formData["patientOccupation"]
      );
      setTrilogyStatepath(
        "patient.patient.contact_info.email.address",
        formData["reporterEmail"]
      );
      setTrilogyStatepath(
        "patient.patient.contact_info.phone.number",
        formData["reporterPhone"]
      );
      setTrilogyStatepath(
        "patient.patient.contact_info.line1",
        formData["reporterAddressLine1"]
      );
      setTrilogyStatepath(
        "patient.patient.contact_info.line2",
        formData["reporterAddressLine2"]
      );
      setTrilogyStatepath(
        "patient.patient.contact_info.city",
        formData["reporterCity"]
      );
      setTrilogyStatepath(
        "patient.patient.contact_info.state",
        formData["reporterState"]
      );
      setTrilogyStatepath(
        "patient.patient.contact_info.postal_code",
        formData["reporterZip"]
      );
      setTrilogyStatepath(
        "patient.patient.contact_info.country",
        formData["reporterCountry"]
      );
      // set 2nd contact to be employee entering details into ShARP
      setTrilogyStatepath(
        "contacts.contact[1].name.first",
        formData["firstName"]
      );
      setTrilogyStatepath(
        "contacts.contact[1].name.last",
        formData["lastName"]
      );
      setTrilogyStatepath(
        "contacts.contact[1].email.address",
        formData["email"]
      );
      setTrilogyStatepath("contacts.contact[1].type", "employee");
      break;
    case "No":
      setTrilogyStatepath(
        "contacts.contact[0].name.first",
        formData["reporterFirstName"]
      );
      setTrilogyStatepath(
        "contacts.contact[0].name.last",
        formData["reporterLastName"]
      );
      setTrilogyStatepath(
        "contacts.contact[0].email.address",
        formData["reporterEmail"]
      );
      setTrilogyStatepath(
        "contacts.contact[0].phone.number",
        formData["reporterPhone"]
      );
      setTrilogyStatepath(
        "contacts.contact[0].type",
        formData["reporterOccupation"]
      );
      setTrilogyStatepath(
        "contacts.contact[0].address.line1",
        formData["reporterAddressLine1"]
      );
      setTrilogyStatepath(
        "contacts.contact[0].address.line2",
        formData["reporterAddressLine2"]
      );
      setTrilogyStatepath(
        "contacts.contact[0].address.city",
        formData["reporterCity"]
      );
      setTrilogyStatepath(
        "contacts.contact[0].address.state",
        formData["reporterState"]
      );
      setTrilogyStatepath(
        "contacts.contact[0].address.postal_code",
        formData["reporterZip"]
      );
      setTrilogyStatepath(
        "contacts.contact[0].country",
        formData["reporterCountry"]
      );
      // set 2nd contact to be employee entering details into ShARP
      setTrilogyStatepath(
        "contacts.contact[1].name.first",
        formData["firstName"]
      );
      setTrilogyStatepath(
        "contacts.contact[1].name.last",
        formData["lastName"]
      );
      setTrilogyStatepath(
        "contacts.contact[1].email.address",
        formData["email"]
      );
      setTrilogyStatepath("contacts.contact[1].type", "employee");
      break;
  }
  if (
    formData["reportedByPatient"] === "Yes" ||
    formData["reportedByPatient"] === "No"
  ) {
    description += `Do we have permission to contact this person? ${formData["contactTheReporter"]}; \n`;
  } else if (formData["reportedByPatient"] === "I’m the patient") {
    description += "Do we have permission to contact this person? Yes; \n";
  }
  if (formData["reporterRelationship"]) {
    description += `Reporter's relationship to the patient: ${formData["reporterRelationship"]}; \n`;
  }

  // patient
  setTrilogyStatepath("patient.patient.initials", formData["patientInitials"]);
  setTrilogyStatepath("patient.patient.gender", formData["patientGender"]);
  setTrilogyStatepath("patient.patient.dob", convertDate("patientDob"));
  if (formData["isAE"]) {
    setTrilogyStatepath(
      "subcases.adverseEvent.patient.information.age_at_event",
      formData["patientAge"]
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.patient.information.age_at_event_unit",
      formData["patientsAgeUnits"]
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.patient.information.age_group",
      formData["patientAgeGroup"]
    );
  }
  setTrilogyStatepath("patient.patient.age", formData["patientAge"]);
  setTrilogyStatepath("patient.patient.ageUnits", formData["patientsAgeUnits"]);

  // product
  description += `Product name: ${formData["productName"]}; \n`;
  if (formData["batchNumber"] === "unknown") {
    if (formData["isAE"]) {
      setTrilogyStatepath(
        "subcases.adverseEvent.product_section.aeproducts.product_therapy.availability.unavailable",
        true
      );
    }
    if (formData["isPQ"]) {
      setTrilogyStatepath(
        "subcases.productQuality.pqproduct.products.details.availability",
        true
      );
    }
  }
  if (formData["isPQ"]) {
    setTrilogyStatepath(
      "subcases.productQuality.pqproduct.products.details.lotNumber",
      formData["batchNumber"]
    );
    setTrilogyStatepath(
      "subcases.productQuality.pqproduct.products.details.reason",
      getBatchNumberUnavailableMapping("pq")
    );
    description += `Form of Administration: ${formData["formOfAdministration"]}; \n`;
    description += `Product Start Date: ${convertDateForDescription("productDate")}; \n`;
    setTrilogyStatepath(
      "subcases.productQuality.pqproduct.products.details.generalIndication",
      formData["patientConditions"]
    );
  }
  if (formData["isAE"]) {
    setTrilogyStatepath(
      "subcases.adverseEvent.product_section.aeproducts.product_therapy.batch_lot_no",
      formData["batchNumber"]
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.product_section.aeproducts.product_therapy.reason",
      getBatchNumberUnavailableMapping("ae")
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.product_section.aeproducts.product_therapy.unit_dose",
      formData["patientDosage"]
    );
    formData["patientDosage"] && setTrilogyStatepath(
      "subcases.adverseEvent.product_section.aeproducts.product_therapy.unit_units",
      formData["dosageUnits"]
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.product_section.aeproducts.product_therapy.form_of_admin",
      formData["formOfAdministration"]
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.product_section.aeproducts.product_indication.drug_indications",
      formData["patientConditions"]
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.product_section.aeproducts.product_therapy.drug_start_date",
      convertDate("productDate")
    );
  }

  // adverse event
  if (formData["isAE"]) {
    setTrilogyStatepath(
      "subcases.adverseEvent.reactions.reaction.adverse_event",
      formData["adverseEvent"]
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.reactions.reaction.duration.duration_start_date",
      convertDate("firstEventDate")
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.reactions.reaction.duration.duration_end_date",
      convertDate("lastEventDate")
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.reactions.reaction.duration.duration_outcome",
      formData["outcome"]
    );
    setTrilogyStatepath(
      "subcases.adverseEvent.reactions.description.value",
      formData["additionalInformation"]
    );
  }

  // map additional information to MC Description, AE Description (above)
  description += `Additional Information: ${formData["additionalInformation"]}; \n`;

  // product quality complaint
  if (formData["isPQ"]) {
    setTrilogyStatepath(
      "subcases.productQuality.pqproduct.products.description",
      formData["qualityAssuranceDescription"]
    );
    setTrilogyStatepath(
      "summary.productQuality.complaint.processing.available",
      formData["sampleAvailableForReturnDropdown"]
    );

    setTrilogyStatepath(
      "summary.productQuality.complaint.processing.availableReason",
      formData["sampleNotAvailableForReturnReasonDropdown"]
    );
    setTrilogyStatepath(
      "summary.productQuality.complaint.processing.sampleUnavailableReason",
      formData["sampleNotAvailableForReturnReasonTextArea"]
    );

    setTrilogyStatepath(
      "subcases.productQuality.pqproduct.products.complaint.processing.replacement",
      formData["patientRequestReplacementRadio"]
        ? formData["patientRequestReplacementRadio"].toLowerCase()
        : null
    );
  }

  // add concatenated string to Initial Description
  setTrilogyStatepath("summary.narrative.long2", description);
  // other / root level props
  setTrilogyStatepath("affiliate", formData["affiliate"]);
  if (formData["isAE"]) {
    const countryCode = window.apiConfig.regionalConfigs.find(
      (regionalConfig) =>
        regionalConfig["affiliateCountry"] === formData["affiliateLabel"]
    )?.["countryCode"];
    setTrilogyStatepath(
      "subcases.adverseEvent.aerinfo.safety.country_of_reporter",
      countryCode ? countryCode : null
    );
  }

  return caseObj;
}
