import { Children, isValidElement, cloneElement, FC, useState } from "react";
import "./styles.scss";
import { Toggle } from "carbon-components-react";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../../../hooks/useWindowSize";

const { MOBILE_MAX } = deviceWidthBreakpoints;
  
interface UnknownToggleProps {
  id: string;
  error?: string;
  size?: string;
  disabled?: boolean;
  defaultToggled?: boolean;
  className?: string;
}

const UnknownToggle: FC<UnknownToggleProps> = (props) => {
  const {
    error,
    disabled,
    children,
    id,
    size = "extra-large",
    defaultToggled,
    className
  } = props;
  const [toggleValue, setToggleValue] = useState(defaultToggled || false);

  const { width } = useWindowSize();

  return (
    <div className={`toggle-container ${size} ${width <= MOBILE_MAX && 'fields-container-mobile'}`}>
      {
        // pass toggle value to child component(s)
        Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, { toggleValue });
          }
          return child;
        })
      }
      <div className={`toggle ${className}`}>
        <Toggle
          labelA={false}
          labelB={false}
          id={id}
          aria-label="unknown toggle"
          labelText="Unknown"
          error={error}
          disabled={disabled}
          onToggle={(value) => {
            setToggleValue(value);
          }}
          defaultToggled={defaultToggled}
        />
      </div>
    </div>
  );
};

export default UnknownToggle;
