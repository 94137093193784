import { FC, useState, useEffect } from "react";
import PreventNavigateModal from "../index";

interface PreventNavigateProps {}

const PreventNavigate: FC<PreventNavigateProps> = () => {
  //   useEffect(() => {
  //   // Disallow browser back
  //   history.pushState(null, "", location.href);
  //   window.addEventListener("popstate", (e) => {
  //     // alert("Don't use browser back.");
  //     history.go(1);
  //   });
  // });

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "Are you sure you want to navigate away?";
    // setOpen(true);
  };

  const [open, setOpen] = useState(false);

  return (
    <div>
      <PreventNavigateModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default PreventNavigate;
