import { FC } from "react";
import { Tooltip } from "../../../index";
import questionCircle from "../../../../assets/question-circle.svg";

interface QuestionTooltipProps {
  message: string;
}

const QuestionTooltip: FC<QuestionTooltipProps> = (props) => {
  const { message } = props;

  return (
    <Tooltip
      message={message}
      direction={"right"}
      trigger={<img src={questionCircle} alt="help?" />}
    />
  );
};

export default QuestionTooltip;
