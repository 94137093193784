import { FC } from "react";
import "./styles.scss";
import LogoutModal from "../modal/logoutModal";
import sharpLogo from "../../assets/SHARP Logo-22-cropped.png";

interface HeaderProps {
  user?: string;
  formData?: any;
}

const Header: FC<HeaderProps> = ({ formData }) => {
  return (
    <div id="header-container">
      <img src={sharpLogo} className="sharp-logo" alt="ShARP Logo" />
      <div className="logo-text">Shared AbbVie Reporting Platform</div>
      {formData?.user && (
        <div>
          <h1>{formData.user}</h1>
          <LogoutModal />
        </div>
      )}
    </div>
  );
};

export default Header;
