import { ProgressIndicator, ProgressStep } from "carbon-components-react";
import "./styles.scss";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../hooks/useWindowSize";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

interface ProgressIndicatorProps {
  currentIndex?: number;
}

const stepLabelArray = [
  "Issue Type",
  "Reporter",
  "Patient",
  "Product",
  "Adverse Event",
  "Product Quality Complaint",
  "Review",
];

// shorter labels for tablet view
const tabletStepLabelArray = [
  "Issue",
  "Reporter",
  "Patient",
  "Product",
  "AE",
  "PQ",
  "Review",
];


const Progress = ({ currentIndex = -1 }: ProgressIndicatorProps) => {
  const { width } = useWindowSize();

  const getLabel = (index) => {
    if (width <= MOBILE_MAX) {
      return null
    } else if (width <= TABLET_MAX) {
      return tabletStepLabelArray[index]
    } else return stepLabelArray[index]
  }

  return (
    <div className={`progress ${width <= TABLET_MAX ? "progress-tablet" : ""} ${width <= MOBILE_MAX ? "progress-mobile" : ""}`}>
      {width > TABLET_MAX && <h1 className={width <= TABLET_MAX ? "progress-header-mobile" : ""}>Form Progress</h1>}
      <ProgressIndicator vertical={width > TABLET_MAX} currentIndex={currentIndex}>
        {stepLabelArray.map((label, index) => (
          <ProgressStep key={index} label={getLabel(index)} role="tab" />
        ))}
      </ProgressIndicator>
    </div>
  );
};

export default Progress;
