import { FC, useEffect } from "react";
import "./styles.scss";
import { Checkbox as CarbonCheckbox } from "carbon-components-react";

interface CheckboxProps {
  label: string;
  name: string;
  control: any;
  Controller: any;
  setValue?: Function;
  error?: string;
  size?: string;
  disabled?: boolean;
  toggleValue?: boolean;
  resetField?: any;
  defaultChecked?: boolean;
}

const Checkbox: FC<CheckboxProps> = (props) => {
  const {
    name,
    label,
    error,
    disabled,
    control,
    Controller,
    setValue = () => {},
    toggleValue,
    size = "extra-large",
    resetField,
    defaultChecked,
  } = props;

  useEffect(() => {
    if (toggleValue) {
      // this setValue is what triggers the watch above
      setValue(name, "unknown", { shouldValidate: true });
    } else if (!toggleValue && resetField) {
      resetField(name);
    } else if (!toggleValue) {
      setValue(name, "");
    }
  }, [toggleValue]);

  useEffect(() => {
    if (defaultChecked) {
      setValue(name, defaultChecked, {
        shouldValidate: true,
      });
    }
  }, []);

  return (
    <div className={size}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur } }) => (
          <CarbonCheckbox
            id={name}
            labelText={label}
            onChange={onChange}
            disabled={disabled || toggleValue}
            onBlur={onBlur}
            invalid={!!error}
            invalidText={error}
            defaultChecked={defaultChecked}
          />
        )}
      />
    </div>
  );
};

export default Checkbox;
