import { FC, useState, useEffect } from "react";
import "./styles.scss";
import {
  Radio,
  TextInput,
  Dropdown,
  UnknownToggle,
  ButtonGroup,
  QuestionTooltip,
} from "../../components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { getOptionData } from "../../utilities/helpers";
import { tooltipMessages, resetFields } from "../index";
import {reporterAddressFields, reporterEmailFields, reporterPhoneFields} from "./helper";

interface ReporterProps {
  goNextSection: Function;
  formData: object;
}

interface stateType {
  fromReview: boolean;
}

const Reporter: FC<ReporterProps> = ({ goNextSection, formData }) => {
  const patientOccupationPlaceholder = "Select...";
  const reporterOccupationPlaceholder = "Select...";
  const reporterCountryPlaceholder = "Select...";

  const [shouldValidate, setShouldValidate] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const requiredWhenNotPatient = yup.string().when("reportedByPatient", {
    is: "No",
    then: yup.string().required("Required"),
    otherwise: yup.string()
  });
  const requiredWhenPatient = yup.string().when("reportedByPatient", {
    is: "Yes",
    then: yup.string().required("Required"),
    otherwise: yup.string()
  });
  const requiredWhenPhoneIsUnknown = yup
    .string()
    .when(
      [
        "reportedByPatient",
        "contactTheReporter",
        "reporterEmail",
        "reporterPhone",
      ],
      {
        is: (
          reportedByPatient,
          contactTheReporter,
          reporterEmail,
          reporterPhone
        ) => {
          return (
            (reportedByPatient === "No" || reportedByPatient === "Yes") &&
            contactTheReporter === "Yes" &&
            reporterEmail === "unknown" &&
            reporterPhone === "unknown"
          );
        },
        then: yup.string().required("Required"),
        otherwise: yup.string(),
      }
    );
  const dropdownRequiredWhenPhoneIsUnknown = yup
    .string()
    .when(
      [
        "reportedByPatient",
        "contactTheReporter",
        "reporterEmail",
        "reporterPhone",
      ],
      {
        is: (
          reportedByPatient,
          contactTheReporter,
          reporterEmail,
          reporterPhone
        ) => {
          return (
            (reportedByPatient === "No" || reportedByPatient === "Yes") &&
            contactTheReporter === "Yes" &&
            reporterEmail === "unknown" &&
            reporterPhone === "unknown"
          );
        },
        then: yup
          .string()
          .required("Required")
          .notOneOf([reporterCountryPlaceholder], "Required"),
        otherwise: yup.string(),
      }
    );

  const dropdownRequiredWhenPatient =
      yup.string().when("reportedByPatient", {
        is: "Yes",
        then: yup.string().required("Required").notOneOf([patientOccupationPlaceholder], "Required"),
        otherwise: yup.string()
      });

  const dropdownRequiredWhenNotPatient = yup.string().when("reportedByPatient", {
    is: "No",
    then: yup.string().required("Required").notOneOf([reporterOccupationPlaceholder], "Required"),
    otherwise: yup.string()
  });

  const schema = shouldValidate
    ? yup.object().shape({
        reportedByPatient: yup.string().required("Required"),
        reporterFirstName: requiredWhenNotPatient,
        reporterLastName: requiredWhenNotPatient,
        reporterRelationship: requiredWhenNotPatient,
        reporterOccupation: dropdownRequiredWhenNotPatient,
        patientFirstName: requiredWhenPatient,
        patientLastName: requiredWhenPatient,
        patientOccupation: dropdownRequiredWhenPatient,
        contactTheReporter: yup
          .string()
          .when("reportedByPatient", {
            is: "No",
            then: yup.string().required("Required"),
            otherwise: yup.string()
          })
          .when("reportedByPatient", {
            is: "Yes",
            then: yup.string().required("Required"),
            otherwise: yup.string()
          }),
        reporterEmail: yup
          .string()
          .when(["reportedByPatient", "contactTheReporter"], {
            is: (reportedByPatient, contactTheReporter) => {
              return (
                (reportedByPatient === "No" || reportedByPatient === "Yes") &&
                contactTheReporter === "Yes"
              );
            },
            then: yup.lazy((value) =>
              value === "unknown"
                ? yup
                    .string()
                    .matches(/(unknown)/)
                    .required()
                : yup
                    .string()
                    .email("Email address is not valid")
                    .required("Required")
            ),
            otherwise: yup.string(),
          }),
        reporterPhone: yup
          .string()
          .when(["reportedByPatient", "reporterEmail"], {
            is: (reportedByPatient, reporterEmail) => {
              return (
                (reportedByPatient === "No" || reportedByPatient === "Yes") &&
                reporterEmail === "unknown"
              );
            },
            then: yup.lazy((value) =>
              value === "unknown"
                ? yup
                    .string()
                    .matches(/(unknown)/)
                    .required("Required")
                : yup
                    .string()
                    .matches(phoneRegExp, "Phone number is not valid")
                    .required("Required")
            ),
            otherwise: yup.string(),
          }),
        reporterAddressLine1: requiredWhenPhoneIsUnknown,
        reporterAddressLine2: yup.string(),
        reporterCity: requiredWhenPhoneIsUnknown,
        reporterState: requiredWhenPhoneIsUnknown,
        reporterZip: requiredWhenPhoneIsUnknown,
        reporterCountry: dropdownRequiredWhenPhoneIsUnknown,
      })
    : yup.object().shape({});

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    formState: { errors, isValid },
    resetField,
    unregister,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const reportedByPatientValue = watch("reportedByPatient");
  const contactTheReporterValue = watch("contactTheReporter");
  const reporterEmailValue = watch("reporterEmail");
  const reporterPhoneValue = watch("reporterPhone");

  let history = useHistory();

  const [nextProgressIndex, setNextProgressIndex] = useState(2);
  const [nextSection, setNextSection] = useState("/patient");

  const onBack = () => {
    setNextProgressIndex(0);
    setNextSection("/issue");
    setShouldValidate(false);
  };

  const onSubmit = (data, e) => {
    goNextSection(data, nextProgressIndex);
    history.push(nextSection);
  };

  const { state } = useLocation<stateType>();

  const onBackToReview = () => {
    setNextProgressIndex(6);
    setNextSection("/review");
  };

  const reactFormProps = {
    control,
    Controller,
    setValue,
    getValues,
    watch,
    resetField,
    unregister
  };

  const issueReportedByPatientTooltip = (
    <QuestionTooltip message={tooltipMessages.issueReportedByPatient} />
  );

  const reportedByPatientOptions = [
    {
      value: "Yes",
      label: "Yes, the patient informed me directly",
    },
    {
      value: "No",
      label: "No, a third party informed me about the patient",
    },
    {
      value: "I’m the patient",
      label: "I’m the patient",
    },
  ];

  const contactTheReporterOptions = [
    {
      value: "Yes",
      label: "Yes",
    },
    {
      value: "No",
      label: "No",
    },
  ];

  useEffect(() => {
    // Needed for default value to work for radio buttons
    if (formData["reportedByPatient"]) {
      setValue("reportedByPatient", formData["reportedByPatient"], {
        shouldValidate: true,
      });
    }
    if (formData["contactTheReporter"]) {
      setValue("contactTheReporter", formData["contactTheReporter"], {
        shouldValidate: true,
      });
    }
    setIsLoading(false);
  }, []);

  let reporterEmail;
  if (contactTheReporterValue === "Yes") {
      reporterEmail = reporterEmailFields(formData, reactFormProps, errors);
  }

  let reporterPhone;
  if (contactTheReporterValue === "Yes" &&
      reporterEmailValue === "unknown") {
      reporterPhone = reporterPhoneFields(formData, reactFormProps, errors);
  }

  let reporterAddress;
  if (contactTheReporterValue === "Yes" &&
      reporterEmailValue === "unknown" &&
      reporterPhoneValue === "unknown") {
      reporterAddress = reporterAddressFields(formData, reactFormProps, errors, reporterCountryPlaceholder);
  }

    useEffect(() => {
        if (!isLoading && !reporterAddress) {
            resetFields([
                "reporterAddressLine1",
                "reporterAddressLine2",
                "reporterCity",
                "reporterState",
                "reporterZip",
                "reporterCountry",
                "reporterCountryLabel"], unregister, formData, resetField);
        }
    }, [reporterAddress])

  return (
    <div className="tab-container">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="tab-title">Reporter Information</div>
        <div className="form-sections-container">
          <div className="form-section">
            <Radio
              {...reactFormProps}
              name="reportedByPatient"
              items={reportedByPatientOptions}
              error={errors.reportedByPatient?.message}
              label="Was this issue reported by the patient? *"
              QuestionTooltip={issueReportedByPatientTooltip}
              defaultValue={getOptionData(
                reportedByPatientOptions,
                formData["reportedByPatient"]
              )}
            />
          </div>
          {reportedByPatientValue === "Yes" && (
            <div className="form-section">
              <div className="sub-section-title">
                What is the patient's information?
              </div>
              <UnknownToggle
                id={"patientFirstNameToggle"}
                defaultToggled={formData["patientFirstName"] === "unknown"}
              >
                <TextInput
                  {...reactFormProps}
                  name="patientFirstName"
                  label="First Name *"
                  placeholderText={"First Name"}
                  error={errors.patientFirstName?.message}
                  size={"extra-large"}
                  defaultValue={formData["patientFirstName"]}
                />
              </UnknownToggle>
              <UnknownToggle
                id={"patientLastNameToggle"}
                defaultToggled={formData["patientLastName"] === "unknown"}
              >
                <TextInput
                  {...reactFormProps}
                  name="patientLastName"
                  label="Last Name *"
                  placeholderText={"Last Name"}
                  error={errors.patientLastName?.message}
                  size={"extra-large"}
                  defaultValue={formData["patientLastName"]}
                />
              </UnknownToggle>
              <UnknownToggle
                id={"patientOccupationToggle"}
                defaultToggled={formData["patientOccupation"] === "unknown"}
              >
                <Dropdown
                  {...reactFormProps}
                  name="patientOccupation"
                  label="Occupation *"
                  items={window.dropdownOptions?.type_of_contact}
                  placeholder={patientOccupationPlaceholder}
                  size={"extra-large"}
                  error={errors.patientOccupation?.message}
                  defaultItem={getOptionData(
                    window.dropdownOptions?.type_of_contact,
                    formData["patientOccupation"]
                  )}
                />
              </UnknownToggle>
            </div>
          )}
          {reportedByPatientValue === "No" && (
            <>
              <div className="form-section">
                <div className="sub-section-title">
                  What is the third party's information?
                </div>
                <UnknownToggle
                  id={"reporterFirstNameToggle"}
                  defaultToggled={formData["reporterFirstName"] === "unknown"}
                >
                  <TextInput
                    {...reactFormProps}
                    name="reporterFirstName"
                    label="First Name *"
                    placeholderText={"First Name"}
                    error={errors.reporterFirstName?.message}
                    size={"extra-large"}
                    defaultValue={formData["reporterFirstName"]}
                  />
                </UnknownToggle>
                <UnknownToggle
                  id={"reporterLastNameToggle"}
                  defaultToggled={formData["reporterLastName"] === "unknown"}
                >
                  <TextInput
                    {...reactFormProps}
                    name="reporterLastName"
                    label="Last Name *"
                    placeholderText={"Last Name"}
                    error={errors.reporterLastName?.message}
                    size={"extra-large"}
                    defaultValue={formData["reporterLastName"]}
                  />
                </UnknownToggle>
                <UnknownToggle
                  id={"reporterRelationshipToggle"}
                  defaultToggled={
                    formData["reporterRelationship"] === "unknown"
                  }
                >
                  <TextInput
                    {...reactFormProps}
                    name="reporterRelationship"
                    label="Relationship to Patient *"
                    placeholderText={"Describe Relationship"}
                    error={errors.reporterRelationship?.message}
                    size={"extra-large"}
                    defaultValue={formData["reporterRelationship"]}
                  />
                </UnknownToggle>
                <UnknownToggle
                  id={"reporterOccupationToggle"}
                  defaultToggled={formData["reporterOccupation"] === "unknown"}
                >
                  <Dropdown
                    {...reactFormProps}
                    name="reporterOccupation"
                    label="Occupation *"
                    items={window.dropdownOptions?.type_of_contact}
                    placeholder={reporterOccupationPlaceholder}
                    size={"extra-large"}
                    error={errors.reporterOccupation?.message}
                    defaultItem={getOptionData(
                      window.dropdownOptions?.type_of_contact,
                      formData["reporterOccupation"]
                    )}
                  />
                </UnknownToggle>
              </div>
            </>
          )}
          {(reportedByPatientValue === "No" ||
            reportedByPatientValue === "Yes") && (
            <>
              <div className="form-section">
                <Radio
                  name="contactTheReporter"
                  {...reactFormProps}
                  items={contactTheReporterOptions}
                  error={errors.contactTheReporter?.message}
                  label="Do we have permission to contact this person? *"
                  defaultValue={getOptionData(
                    contactTheReporterOptions,
                    formData["contactTheReporter"]
                  )}
                />
              </div>
              {contactTheReporterValue === "Yes" && (
                <div className="form-section">
                  <div className="first-child-padding"></div>
                    {reporterEmail}
                    {reporterPhone}
                    {reporterAddress}
                </div>
              )}
            </>
          )}
        </div>
        <div className="form-button-container">
          <ButtonGroup
            disabled={!isValid}
            secondaryOnClick={onBack}
            fromReview={state?.fromReview}
            onBackToReview={onBackToReview}
          />
        </div>
      </form>
    </div>
  );
};

export default Reporter;
