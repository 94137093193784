import { FC, useState, useEffect } from "react";
import "./styles.scss";
import {
  Dropdown,
  UnknownToggle,
  ButtonGroup,
  TextArea,
  DatePicker,
  QuestionTooltip,
} from "../../components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { tooltipMessages } from "../index";
import moment from "moment";
import { getOptionData, getDefaultDate } from "../../utilities/helpers";

interface AdverseEventProps {
  goNextSection: Function;
  formData: object;
  isAE: boolean;
}

interface stateType {
  fromReview: boolean;
}

const AdverseEvent: FC<AdverseEventProps> = ({
  goNextSection,
  formData,
  isAE,
}) => {
  const [shouldValidate, setShouldValidate] = useState(true);

  const schema = shouldValidate
    ? yup.object().shape({
        adverseEvent: yup.string().when("$isAE", {
          is: true,
          then: (schema) => schema.required("Required"),
        }),
        firstEventDate: yup.string(),
        lastEventDate: yup.string(),
        outcome: yup.string(),
      })
    : yup.object().shape({});

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    context: { isAE },
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const outcomePlaceholder = "Select...";

  let history = useHistory();
  const { state } = useLocation<stateType>();

  const [nextProgressIndex, setNextProgressIndex] = useState(5);
  const [nextSection, setNextSection] = useState("/qualityassurance");

  const onBack = () => {
    setNextProgressIndex(3);
    setNextSection("/product");
    setShouldValidate(false);
  };

  const onBackToReview = () => {
    setNextProgressIndex(6);
    setNextSection("/review");
  };

  const onSubmit = (data) => {
    goNextSection(data, nextProgressIndex);
    history.push(nextSection);
  };

  const reactFormProps = {
    control,
    Controller,
    setValue,
    getValues,
    watch,
    resetField,
  };

  useEffect(() => {
    getDefaultDate("firstEventDate", formData["firstEventDate"], setValue);
    getDefaultDate("lastEventDate", formData["lastEventDate"], setValue);
  }, []);

  const additionalInfoForAETooltip = (
    <QuestionTooltip message={tooltipMessages.additionalInfoForAE} />
  );

  return (
    <div className="tab-container">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="tab-title">Adverse Event</div>
        <div className="form-sections-container">
          <div className="form-section">
            <TextArea
              {...reactFormProps}
              control={control}
              Controller={Controller}
              label={`What was the adverse event(s) that was reported?${
                isAE ? " *" : ""
              }`}
              name="adverseEvent"
              error={errors.adverseEvent?.message}
              size="extra-large"
              defaultValue={formData["adverseEvent"]}
            />
          </div>
          <div className="form-section">
            <div className="first-child-padding"></div>
            <UnknownToggle
              id="firstEventDateToggle"
              defaultToggled={formData["firstEventDate"] === "unknown"}
            >
              <DatePicker
                {...reactFormProps}
                name="firstEventDate"
                error={errors.firstEventDate?.message}
                label="When did the patient first experience this event(s)?"
                defaultValue={
                  formData["firstEventDate"]
                    ? moment(formData["firstEventDate"])
                    : null
                }
                defaultType={formData["firstEventDateType"]}
              />
            </UnknownToggle>
            <div className="first-child-padding"></div>
            <UnknownToggle
              id="lastEventDateToggle"
              defaultToggled={formData["lastEventDate"] === "unknown"}
            >
              <DatePicker
                {...reactFormProps}
                name="lastEventDate"
                error={errors.lastEventDate?.message}
                label="When did the patient last experience this event(s)?"
                defaultValue={
                  formData["lastEventDate"]
                    ? moment(formData["lastEventDate"])
                    : null
                }
                defaultType={formData["lastEventDateType"]}
              />
            </UnknownToggle>
          </div>
          <div className="form-section">
            <Dropdown
              {...reactFormProps}
              name="outcome"
              label="What was the final outcome of the reported event(s)?"
              items={window.dropdownOptions?.duration_outcome}
              placeholder={outcomePlaceholder}
              size="large"
              error={errors.outcome?.message}
              defaultItem={getOptionData(
                window.dropdownOptions?.duration_outcome,
                formData["outcome"]
              )}
            />
            <TextArea
              {...reactFormProps}
              control={control}
              Controller={Controller}
              label="Is there any additional information you would like to share concerning this specific event(s)?"
              name="additionalInformation"
              error={errors.patientConditions?.message}
              size="extra-large"
              QuestionTooltip={additionalInfoForAETooltip}
              defaultValue={formData["additionalInformation"]}
            />
          </div>
        </div>
        <div className="form-button-container">
          <ButtonGroup
            disabled={!isValid}
            secondaryOnClick={onBack}
            fromReview={state?.fromReview}
            onBackToReview={onBackToReview}
          />
        </div>
      </form>
    </div>
  );
};

export default AdverseEvent;
