import { FC, useEffect } from "react";
import "./styles.scss";
import {
  RadioButton,
  RadioButtonGroup,
  FormGroup,
} from "carbon-components-react";
import { resetFields } from "../../../../pages";

interface RadioProps {
  label: string;
  name: string;
  items: any;
  control: any;
  Controller: any;
  error?: string;
  size?: string;
  disabled?: boolean;
  QuestionTooltip?: any;
  defaultValue?: any;
  toggleValue?: boolean;
  setValue?: Function;
  resetField?: any;
}

const Radio: FC<RadioProps> = (props) => {
  const {
    name,
    label,
    error,
    disabled,
    items,
    control,
    Controller,
    size = "extra-large",
    QuestionTooltip,
    defaultValue,
    toggleValue,
    setValue = () => {},
    resetField
  } = props;

  useEffect(() => {
    if (toggleValue) {
      // this setValue is what triggers the watch above
      setValue(name, "unknown", { shouldValidate: true });
    } else if (!toggleValue && resetField) {
      resetField(name);
    } else if (!toggleValue) {
      setValue(name, "");
    }
  }, [toggleValue]);

  return (
    <div className={size}>
      <Controller
        name={name}
        control={control}
        // defaultValue={defaultValue}
        render={({ field: { onChange } }) => (
          <FormGroup
            legendText={
              <>
                {label}
                {QuestionTooltip}
              </>
            }
          >
            <RadioButtonGroup
              name={name}
              disabled={disabled || toggleValue}
              id={name}
              invalid={!!error}
              invalidText={error}
              orientation="vertical"
              onChange={(selectedItem) => {
                onChange(selectedItem);
              }}
              defaultSelected={defaultValue?.value}
            >
              {items.map((item, index) => (
                <RadioButton
                  key={index}
                  labelText={item.label || item}
                  value={item.value || item}
                />
              ))}
            </RadioButtonGroup>
          </FormGroup>
        )}
      />
    </div>
  );
};

export default Radio;
