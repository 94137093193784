import {Dropdown, TextInput, UnknownToggle} from "../../components";
import {getOptionData} from "../../utilities/helpers";

export const patientAgeFields = (formData, reactFormProps, patientAgeUnitsPlaceholder, errors) => (
    <div className="sibling-fields">
    <UnknownToggle
        id="patientAgeToggle"
        defaultToggled={formData["patientAge"] === "unknown"}
    >
        <TextInput
            {...reactFormProps}
            name="patientAge"
            className="patientAge"
            error={errors.patientAge?.message}
            placeholderText="Age"
            label="Patient's Age *"
            size={"medium"}
            defaultValue={formData["patientAge"]}
        />
        <Dropdown
            {...reactFormProps}
            name="patientsAgeUnits"
            className="patientsAgeUnits"
            label="&nbsp;"
            placeholder={patientAgeUnitsPlaceholder}
            items={window.dropdownOptions?.age_units}
            error={errors.patientsAgeUnits?.message}
            size={"large"}
            defaultItem={getOptionData(
                window.dropdownOptions?.age_units,
                formData["patientsAgeUnits"]
            )}
        />
    </UnknownToggle></div>
);

export const patientAgeGroupFields = (formData, reactFormProps, patientAgeGroupPlaceholder, errors) => (
    <UnknownToggle
        id="patientAgeGroupToggle"
        defaultToggled={formData["patientAgeGroup"] === "unknown"}
    >
        <Dropdown
            {...reactFormProps}
            name="patientAgeGroup"
            label="Patient's Age Group *"
            placeholder={patientAgeGroupPlaceholder}
            items={window.dropdownOptions?.age_group}
            error={errors.patientAgeGroup?.message}
            defaultItem={getOptionData(
                window.dropdownOptions?.age_group,
                formData["patientAgeGroup"]
            )}
        />
    </UnknownToggle>
)