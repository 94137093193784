function parseJwt(jwt: string) {
  return jwt
    ?.split(".")
    ?.slice(0, 2)
    .map((val) => JSON.parse(window.atob(val)))
    .reduce((_, val) => Object.assign({}, val));
}

function redirectIdp() {
  const issuer = window?.apiConfig?.issuer;
  const params = issuer ? `?issuer=${issuer}` : "";

  const req = new XMLHttpRequest();
  req.open("GET", `${window.apiConfig.core.uri}/auth/request${params}`, true);

  req.onreadystatechange = () => {
    if (req.readyState < 4) return;
    const { actionUri, samlRequest, stateParam } = JSON.parse(req.responseText);
    const callbackUri = window.location.href;

    // Create a disconnected form
    const $f = document.createElement("form");
    $f.action = actionUri;
    $f.method = "POST";

    // Create the saml request
    const $samlRequest = document.createElement("input");
    $samlRequest.type = "hidden";
    $samlRequest.name = "SAMLRequest";
    $samlRequest.value = samlRequest;
    $f.appendChild($samlRequest);

    // Create the relay state
    const $relayState = document.createElement("input");
    $relayState.type = "hidden";
    $relayState.name = stateParam;
    $relayState.value = callbackUri;
    $f.appendChild($relayState);

    // Append the form to the body
    document.body.appendChild($f);

    // Go to login
    $f.submit();
  };

  req.send(null);
}

export function getToken() {
  return document.cookie
    .split("; ")
    ?.find((row) => row.startsWith("jwt="))
    ?.split("=")[1];
}

export function getAuth() {
  const cookie = document.cookie
    .split("; ")
    ?.find((row) => row.startsWith("jwt="))
    ?.split("=")[1];
  if (cookie) {
    const jwtData = parseJwt(cookie);
    const expDate = new Date(jwtData?.exp * 1000);
    const now = new Date();
    if (expDate < now) {
      document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:01 GMT";
      redirectIdp();
    } else {
      return jwtData;
    }
  } else {
    const queryParam = new URLSearchParams(window.location.search).get("jwt");
    if (queryParam) {
      document.cookie = `jwt=${queryParam}; SameSite=Strict; Secure`;
      window.location.replace(
        window.location.href.replace(window.location.search, "")
      );
    } else {
      redirectIdp();
    }
  }
}
