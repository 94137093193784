import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal as CarbonModal } from "carbon-components-react";
import { Button, ModalStateManager } from "../../index";
import { Copy16, ArrowRight32 } from "@carbon/icons-react";
import { createCase } from "../../../utilities/api";
import "../styles.scss";

interface SubmitModalProps {
  formData: object;
  clearState: Function;
}

const SubmitModal: FC<SubmitModalProps> = ({ formData, clearState }) => {
  const [caseId, setCaseId] = useState("");
  let history = useHistory();
  return (
    <ModalStateManager
      renderLauncher={({ setOpen, setLoading }) => (
        <Button
          label={"Confirm and Submit"}
          onClick={() => {
            setLoading(true);
            createCase(formData)
              .then((returnedCaseId) => {
                setLoading(false);
                if (returnedCaseId) {
                  setCaseId(returnedCaseId);
                  setOpen(true);
                } else {
                  history.push("/error");
                }
              })
              .catch(() => history.push("/error"));
          }}
          renderIcon={ArrowRight32}
        />
      )}
    >
      {({ open, setOpen, setHasCopied, hasCopied }) => (
        <CarbonModal
          modalHeading={"Thank you! Your form has been submitted."}
          primaryButtonText={"Close"}
          open={open}
          size={"xs"}
          onRequestClose={() => {
            setOpen(false);
            clearState();
            history.push("/");
          }}
          onRequestSubmit={() => {
            setOpen(false);
            clearState();
            history.push("/");
          }}
        >
          <div className="modal-content">
            <div
              className="copy-icon"
              onClick={() => {
                navigator.clipboard.writeText(caseId);
                setHasCopied(true);
              }}
            >
              <Copy16 />
            </div>
            <div>
              <div className="case-id">
                Case ID: {caseId}
                <br />
                <div className="modal-subtitle">
                  Please document the Case ID for future reference.
                </div>
                {hasCopied && (
                  <div className="copied-text">
                    {"Case ID copied to clipboard"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </CarbonModal>
      )}
    </ModalStateManager>
  );
};

export default SubmitModal;
