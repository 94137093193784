import { FC, useEffect, useState } from "react";
import "./styles.scss";
import { useHistory, useLocation } from "react-router-dom";
import {
  Dropdown,
  TextArea,
  UnknownToggle,
  Radio,
  ButtonGroup,
  QuestionTooltip,
} from "../../components";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getOptionData } from "../../utilities/helpers";
import {resetFields, tooltipMessages} from "../index";
import {sampleNotAvailableReasonFields, sampleNotAvailableReturnTextFields} from "./helper";

interface QualityAssuranceProps {
  goNextSection: Function;
  formData: object;
  isPQ: boolean;
}

interface stateType {
  fromReview: boolean;
}

const QualityAssurance: FC<QualityAssuranceProps> = ({
  goNextSection,
  isPQ,
  formData,
}) => {
  const sampleAvailableForReturnDropdownPlaceholder = "Select...";
  const sampleNotAvailableForReturnReasonPlaceholder = "Select...";

  const [shouldValidate, setShouldValidate] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  let history = useHistory();
  const { state } = useLocation<stateType>();

  const requiredWhenIsPQ = yup.string().when("$isPQ", {
    is: true,
    then: (schema) => schema.required("Required"),
  });

  const schema = shouldValidate
    ? yup.object().shape({
        qualityAssuranceDescription: requiredWhenIsPQ,
        sampleAvailableForReturnDropdown: yup
          .string()
          .when("$isPQ", {
            is: true,
            then: (schema) =>
              schema
                .required("Required").notOneOf([sampleAvailableForReturnDropdownPlaceholder], "Required"),
          }),
        sampleNotAvailableForReturnReasonDropdown: yup
          .string()
          .when(["$isPQ", "sampleAvailableForReturnDropdown"], {
            is: (isPQ, sampleAvailableForReturnDropdown) =>
              isPQ === true &&
                sampleAvailableForReturnDropdown === "no",
            then: yup
              .string()
              .required("Required").notOneOf([sampleNotAvailableForReturnReasonPlaceholder], "Required")
          }),
        sampleNotAvailableForReturnReasonTextArea: yup
          .string()
          .when(["$isPQ", "sampleNotAvailableForReturnReasonDropdown"], {
            is: (isPQ, sampleNotAvailableForReturnReasonDropdown) =>
              isPQ === true &&
              sampleNotAvailableForReturnReasonDropdown === "other",
            then: yup.string().required("Required")
          }),
        patientRequestReplacementRadio: yup.string().when("$isPQ", {
          is: true,
          then: (schema) => schema.required("Required"),
        }),
      })
    : yup.object().shape({});

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    resetField,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    context: { isPQ },
    mode: "all",
    resolver: yupResolver(schema),
  });

  const reactFormProps = {
    Controller,
    control,
    setValue,
    getValues,
    watch,
    resetField,
    unregister,
  };

  const [nextProgressIndex, setNextProgressIndex] = useState(6);
  const [nextSection, setNextSection] = useState("/review");

  const onBack = () => {
    setNextProgressIndex(4);
    setNextSection("/adverseEvent");
    setShouldValidate(false);
  };

  const onSubmit = (data) => {
    goNextSection(data, nextProgressIndex);
    history.push(nextSection);
  };

  const sampleAvailableForReturnValue = watch(
    "sampleAvailableForReturnDropdown"
  );
  const whySampleNotAvailableForReturnValue = watch(
    "sampleNotAvailableForReturnReasonDropdown"
  );

  const pqComplaintAndOutcomeTooltip = (
    <QuestionTooltip message={tooltipMessages.pqComplaintAndOutcome} />
  );
  const pqReplacementRequestedTooltip = (
    <QuestionTooltip message={tooltipMessages.pqReplacementRequested} />
  );

  const patientRequestReplacementRadioOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  useEffect(() => {
    if (formData["patientRequestReplacementRadio"]) {
      setValue(
        "patientRequestReplacementRadio",
        formData["patientRequestReplacementRadio"],
        {
          shouldValidate: true,
        }
      );
    }
    setIsLoading(false);
  }, []);

  let sampleNotAvailableForReturnReasonDropdown;
  if (sampleAvailableForReturnValue === "no") {
    sampleNotAvailableForReturnReasonDropdown = sampleNotAvailableReasonFields(formData, reactFormProps, errors, sampleNotAvailableForReturnReasonPlaceholder, isPQ);
  }

  let sampleNotAvailableForReturnReasonOtherText;
  if (sampleAvailableForReturnValue === "no" && whySampleNotAvailableForReturnValue === "other") {
    sampleNotAvailableForReturnReasonOtherText = sampleNotAvailableReturnTextFields(formData, reactFormProps, isPQ, "Type description here")
  }

  useEffect(() => {
    if (!isLoading && !sampleNotAvailableForReturnReasonDropdown) {
      resetFields(
          [
              "sampleNotAvailableForReturnReasonDropdown",
              "sampleNotAvailableForReturnReasonDropdownLabel",
              "sampleNotAvailableForReturnReasonTextArea"
          ],
          unregister, formData, resetField
      )
    }
  }, [sampleNotAvailableForReturnReasonDropdown])

  useEffect(() => {
    if (!isLoading && !sampleNotAvailableForReturnReasonOtherText) {
      resetFields(
          [
            "sampleNotAvailableForReturnReasonTextArea"
          ],
          unregister, formData, resetField
      )
    }
  }, [sampleNotAvailableForReturnReasonOtherText])

  return (
    <div className="tab-container">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="tab-title">Product Quality Complaint</div>
        <div className="form-sections-container">
          <div className="form-section">
            <TextArea
              {...reactFormProps}
              name="qualityAssuranceDescription"
              label={`Please provide a description of the product quality complaint(s) and outcome(s)${
                isPQ ? " *" : ""
              }`}
              size="extra-large"
              placeholderText="Type description here"
              error={errors.qualityAssuranceDescription?.message}
              QuestionTooltip={pqComplaintAndOutcomeTooltip}
              defaultValue={formData["qualityAssuranceDescription"]}
            />
          </div>
        </div>
        <div className="form-sections-container">
          <div className="form-section">
            <div className="first-child-padding"></div>
            <UnknownToggle
              id="sampleAvailableForReturnUnknownToggle"
              defaultToggled={
                formData["sampleAvailableForReturnDropdown"] === "unknown"
              }
            >
              <Dropdown
                {...reactFormProps}
                label={`Is the sample available for return? ${
                  isPQ ? " *" : ""
                }`}
                name="sampleAvailableForReturnDropdown"
                items={window.dropdownOptions?.sample_availability}
                error={errors.sampleAvailableForReturnDropdown?.message}
                defaultItem={getOptionData(
                  window.dropdownOptions?.sample_availability,
                  formData["sampleAvailableForReturnDropdown"]
                )}
                placeholder={sampleAvailableForReturnDropdownPlaceholder}
              />
            </UnknownToggle>
            {sampleAvailableForReturnValue === "no" && <div className="first-child-padding"></div>}
            {sampleNotAvailableForReturnReasonDropdown}
            {sampleNotAvailableForReturnReasonOtherText}
          </div>
        </div>
        <div className="form-sections-container">
          <div className="form-section">
          <UnknownToggle
              id="patientRequestReplacementToggle"
              defaultToggled={
                formData["patientRequestReplacementRadio"] === "unknown"
              }
              className="extra-margin"
              >
            <Radio
              {...reactFormProps}
              name="patientRequestReplacementRadio"
              items={patientRequestReplacementRadioOptions}
              label={`Did the patient request a replacement?${
                isPQ ? " *" : ""
              }`}
              QuestionTooltip={pqReplacementRequestedTooltip}
              defaultValue={getOptionData(
                patientRequestReplacementRadioOptions,
                formData["patientRequestReplacementRadio"]
                )}
                />
            </UnknownToggle>
          </div>
        </div>
        <div className="form-button-container">
          <ButtonGroup
            disabled={!isValid}
            secondaryOnClick={onBack}
            fromReview={state?.fromReview}
            onBackToReview={handleSubmit(onSubmit)}
          ></ButtonGroup>
        </div>
      </form>
    </div>
  );
};

export default QualityAssurance;
