import Landing from "./landing";
import Issue from "./issue";
import Reporter from "./reporter";
import Patient from "./patient";
import Product from "./product";
import QualityAssurance from "./qualityassurance";
import AdverseEvent from "./adverseEvent";
import Review from "./review";
import Logout from "./errorPages/logout";
import Error from "./errorPages/error";

export {
  Landing,
  Issue,
  Reporter,
  Patient,
  Product,
  QualityAssurance,
  AdverseEvent,
  Review,
  Logout,
  Error,
};

export const resetFields = (fields: string[], unregister: Function, formData: Object, resetField: Function) => {
  fields.map(field => {
    //This is an extra step, but simply unregistering a field set in a hook doesn't clear its value from react-hook-form
    resetField(field, {shouldValidate: true});
    unregister(field);
    delete formData[field]
  })
}

export const tooltipMessages = {
  affiliateSelection: "Please select your country location.",
  issueReportedByPatient:
    'Only select "Yes" if the event you are reporting has been reported to you by the patient themselves.',
  productNameInput:
    "Enter product name as referred in your region including presentation (if known) (e.g. Botox, Humira PFS, etc.)",
  additionalInfoForAE:
    "Please provide any additional information you may have on the event which was not captured in any other field (e.g. medical history, product strength, patient allergies, etc.)",
  pqComplaintAndOutcome:
    "Please provide all details regarding the complaint including: date of event, number of affected product (e.g. 1 pen, 5 tablets, etc.)",
  pqReplacementRequested:
    'Please select "Yes" if the reporter has communicated to you that they would like a replacement product.',
};
