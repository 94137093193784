import {FC, useEffect, useState} from "react";
import "./styles.scss";
import { Button, Dropdown, QuestionTooltip } from "../../components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowRight32 } from "@carbon/icons-react";
import {
  tooltipMessages,
} from "../index";
import { getDropdownOptions } from "../../utilities/api";
import { getOptionData } from "../../utilities/helpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../hooks/useWindowSize";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

interface LandingProps {
  goNextSection: Function;
  formData: Object;
}

interface stateType {
  fromReview: boolean;
}

const Landing: FC<LandingProps> = ({ goNextSection, formData }) => {
  const { width } = useWindowSize();

  const languagePlaceholder = "Select";
  const affiliatePlaceholder = "Select Affiliate";

  const [nextProgressIndex, setNextProgressIndex] = useState(0);
  const [nextSection, setNextSection] = useState("/issue");

  const affiliateOptions = window.apiConfig.regionalConfigs.map(
      (regionalConfig) => {
        return {
          label: regionalConfig["affiliateCountry"],
          value: regionalConfig["affiliateCode"],
        };
      }
  );

  const schema = yup.object().shape({
    language: yup.string().required("Required").notOneOf([languagePlaceholder], "Required"),
    affiliate: yup.string().required("Required").notOneOf([affiliatePlaceholder], "Required")
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setValue,
    getValues
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  let history = useHistory();

  const onSubmit = (data) => {
    getDropdownOptions(data.affiliate);
    goNextSection(data, nextProgressIndex);
    history.push(nextSection);
  };

  const { state: fromReview } = useLocation<stateType>();

  const onBackToReview = () => {
    setNextProgressIndex(6);
    setNextSection("/review");
  };

  const affiliateSelectionTooltip = (
    <QuestionTooltip message={tooltipMessages.affiliateSelection} />
  );

  const languageOptions = [{ value: "en", label: "English" }];

  useEffect(() => {
    setValue("languageLabel", languageOptions[0].label);
  }, []);

  return (
    <div className="tab-container">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="tab-title">
          Welcome to Shared AbbVie Reporting Platform (ShARP)
        </div>
        <br />
        <div className="page-notification">
          <p>Use this tool to report:</p>
          <ul>
            <li>
            Adverse Events resulting from administration of a medical drug product
            </li>
            <li>
              Product Quality Complaints resulting from the administration of a medical drug product
            </li>
            <li>
              Product Quality Complaints resulting from the use of a medical devices employed in combination with a medical 
              drug product (e.g. Pumps, vaporizers, syringes, etc.)
            </li>
          </ul>
          <p>
            Do NOT report any stand-alone medical device complaints (e.g.: Body Sculpting, Breast Implants, etc.) through ShARP. 
            Please continue following regular process.
          </p>
          <br />
          <p>Please note:</p>
          <ul>
          <li>
            Completing a report should take approximately 5 minutes to fill out
          </li>
          <li>
            Navigating away or refreshing the page while completing a report will result in your data being cleared
          </li>
          <li>
            There is 10-minute time limit for the successful submission of a report. Exceeding 10min will require you
            to restart your submission.
          </li>
          </ul>
        </div>
        <div className="form-sections-container">
          <div className={`form-section ${width > MOBILE_MAX && "form-section-horizontal"}`}>
            <Dropdown
              control={control}
              Controller={Controller}
              setValue={setValue}
              getValues={getValues}
              name="language"
              label="Language *"
              items={languageOptions}
              defaultItem={
                getOptionData(languageOptions, formData["language"]) ||
                languageOptions[0]
              }
              placeholder={languagePlaceholder}
              size={width > MOBILE_MAX ? "medium" : "extra-extra-large"}
              error={errors.language?.message}
            />
            <Dropdown
              control={control}
              Controller={Controller}
              setValue={setValue}
              getValues={getValues}
              name="affiliate"
              label="To which Affiliate do you report? *"
              items={affiliateOptions}
              defaultItem={getOptionData(
                affiliateOptions,
                formData["affiliate"]
              )}
              placeholder={affiliatePlaceholder}
              error={errors.affiliate?.message}
              QuestionTooltip={affiliateSelectionTooltip}
              size={width > MOBILE_MAX ? "large" : "extra-extra-large"}
            />
          </div>
        </div>
        <div className="form-button-container begin-entry-button">
          {!fromReview && (
            <Button
              type="submit"
              label={"Begin Entry"}
              disabled={!isValid}
              renderIcon={ArrowRight32}
            />
          )}
          {fromReview && (
            <div className="back-to-review-button">
              <Button
                label="Back to Review"
                disabled={!isValid}
                kind="primary"
                type="submit"
                onClick={onBackToReview}
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Landing;