import { FC } from "react";
import "../styles.scss";
import { Modal as CarbonModal, Link } from "carbon-components-react";
import { ModalStateManager } from "../../index";
import { useHistory } from "react-router-dom";

interface LogoutModalProps {}

const LogoutModal: FC<LogoutModalProps> = () => {
  let history = useHistory();
  return (
    <ModalStateManager
      renderLauncher={({ setOpen }) => (
        <Link id="logout-link" onClick={() => setOpen(true)}>
          Log Out
        </Link>
      )}
    >
      {({ open, setOpen }) => (
        <CarbonModal
          modalHeading={"Are you sure you want to log out?"}
          primaryButtonText={"Yes, Log Out"}
          secondaryButtonText={"No, Keep Working"}
          open={open}
          size={"xs"}
          onRequestSubmit={() => {
            window.shouldSignInPopup = false;
            document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:01 GMT";
            setOpen(false);
            history.push("/logout");
          }}
          onRequestClose={() => setOpen(false)}
        >
          <div className="modal-content">
            Once you log out, all entered data that has not already been
            submitted will be lost.
          </div>
        </CarbonModal>
      )}
    </ModalStateManager>
  );
};

export default LogoutModal;
