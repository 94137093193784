import { FC, useState, useEffect } from "react";
import "./styles.scss";
import {
  TextInput,
  Dropdown,
  UnknownToggle,
  ButtonGroup,
  TextArea,
  DatePicker,
  QuestionTooltip,
  NumberInput,
} from "../../components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { getOptionData, getDefaultDate } from "../../utilities/helpers";
import moment from "moment";
import {
  resetFields,
  tooltipMessages,
} from "../index";
import {batchLotNumberUnavailableField} from "./helper";

interface ProductProps {
  goNextSection: Function;
  formData: object;
  isAE: boolean;
}

interface stateType {
  fromReview: boolean;
}

const Product: FC<ProductProps> = ({ goNextSection, formData, isAE }) => {
  const whyBatchNumUnavailableClosedPlaceholder = "Select...";
  const dosageUnitsPlaceholder = "Units";
  const formOfAdminPlaceholder = "Select...";

  const [shouldValidate, setShouldValidate] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const schema = shouldValidate
    ? yup.object().shape({
        productName: yup.string().required("Required"),
        batchNumber: yup.string().required("Required"),
        batchNumberUnavailable: yup.string().when("batchNumber", {
          is: "unknown",
          then: yup
            .string()
            .required("Required")
            .notOneOf([whyBatchNumUnavailableClosedPlaceholder], "Required"),
          otherwise: yup.string(),
        }),
        patientDosage: yup.string().when("$isAE", {
          is: true,
          then: (schema) => schema.required("Required"),
        }),
        dosageUnits: yup.string().when("$isAE", {
          is: true,
          then: yup
            .string()
            .required("Required")
            .notOneOf([dosageUnitsPlaceholder], "Required"),
        }),
        formOfAdministration: yup
          .string()
          .required("Required")
          .notOneOf([formOfAdminPlaceholder], "Required"),
        patientConditions: yup.string().required("Required"),
        productDate: yup.string().required("Required"),
      })
    : yup.object().shape({});

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    resetField,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    context: { isAE },
    mode: "all",
    resolver: yupResolver(schema),
  });

  const batchNumberValue = watch("batchNumber");

  let history = useHistory();
  const { state } = useLocation<stateType>();

  const [nextProgressIndex, setNextProgressIndex] = useState(4);
  const [nextSection, setNextSection] = useState("/adverseEvent");

  const onBack = () => {
    setNextProgressIndex(2);
    setNextSection("/patient");
    setShouldValidate(false);
  };

  const onSubmit = (data, e) => {
    goNextSection(data, nextProgressIndex);
    history.push(nextSection);
  };

  const onBackToReview = () => {
    setNextProgressIndex(6);
    setNextSection("/review");
  };

  const reactFormProps = {
    control,
    Controller,
    setValue,
    getValues,
    watch,
    resetField,
    unregister,
  };

  useEffect(() => {
    getDefaultDate("productDate", formData["productDate"], setValue);
    setIsLoading(false);
  }, []);

  const productNameInputTooltip = (
    <QuestionTooltip message={tooltipMessages.productNameInput} />
  );

  let batchNumUnavailable;
  if (batchNumberValue === "unknown") {
    batchNumUnavailable = batchLotNumberUnavailableField(formData, reactFormProps, errors, whyBatchNumUnavailableClosedPlaceholder);
  }

  useEffect(() => {
    if (!isLoading && !batchNumUnavailable) {
      resetFields(
          [
              "batchNumberUnavailable",
              "batchNumberUnavailableLabel"
          ],
          unregister, formData, resetField
      )
    }
  }, [batchNumUnavailable])

  return (
    <div className="tab-container">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="tab-title">Product Information</div>
        <div className="form-sections-container">
          <div className="form-section">
            <TextInput
              label="Product Name *"
              name="productName"
              {...reactFormProps}
              error={errors.productName?.message}
              size="extra-large"
              QuestionTooltip={productNameInputTooltip}
              defaultValue={formData["productName"]}
            />
            <UnknownToggle
              id="batchNumberToggle"
              defaultToggled={formData["batchNumber"] === "unknown"}
            >
              <TextInput
                {...reactFormProps}
                label="Batch/Lot Number *"
                name="batchNumber"
                error={errors.batchNumber?.message}
                defaultValue={formData["batchNumber"]}
              />
            </UnknownToggle>
            {batchNumUnavailable}
          </div>
          <div className="form-section">
            <div className="first-child-padding"></div>
            <div className="sibling-fields">
              <UnknownToggle
                id="patientDosageToggle"
                defaultToggled={formData["patientDosage"] === "unknown"}
              >
                  <NumberInput
                  {...reactFormProps}
                  label={`Patient's Dosage ${isAE ? "*" : ""}`}
                  name="patientDosage"
                  className="patientDosage"
                  error={errors.patientDosage?.message}
                  size="medium"
                  defaultValue={formData["patientDosage"]}
                />
                <Dropdown
                  label="&nbsp;"
                  name="dosageUnits"
                  className="dosageUnits"
                  items={window.dropdownOptions?.unit_select}
                  {...reactFormProps}
                  error={errors.dosageUnits?.message}
                  size="medium"
                  placeholder={dosageUnitsPlaceholder}
                  defaultItem={getOptionData(
                    window.dropdownOptions?.unit_select,
                    formData["dosageUnits"]
                  )}
                />
              </UnknownToggle>
            </div>
            <UnknownToggle
              id="formOfAdministrationToggle"
              defaultToggled={formData["formOfAdministration"] === "unknown"}
            >
              <Dropdown
                label="Form of Administration *"
                name="formOfAdministration"
                items={window.dropdownOptions?.form_of_admin}
                {...reactFormProps}
                error={errors.formOfAdministration?.message}
                size="extra-large"
                defaultItem={getOptionData(
                  window.dropdownOptions?.form_of_admin,
                  formData["formOfAdministration"]
                )}
                placeholder={formOfAdminPlaceholder}
              />
            </UnknownToggle>
          </div>
          <div className="form-section">
            <div className="first-child-padding"></div>
            <UnknownToggle
              id="patientConditionToggle"
              defaultToggled={formData["patientConditions"] === "unknown"}
            >
              <TextArea
                {...reactFormProps}
                label="What condition(s) is the patient getting treated for? *"
                name="patientConditions"
                error={errors.patientConditions?.message}
                size="extra-large"
                defaultValue={formData["patientConditions"]}
              />
            </UnknownToggle>
            <div className="first-child-padding"></div>
            <UnknownToggle
              id="productDateToggle"
              defaultToggled={formData["productDate"] === "unknown"}
            >
              <DatePicker
                {...reactFormProps}
                name="productDate"
                error={errors.productDate?.message}
                label="When did the patient start using the product? *"
                defaultValue={
                  formData["productDate"]
                    ? moment(formData["productDate"])
                    : null
                }
                defaultType={formData["productDateType"]}
              />
            </UnknownToggle>
          </div>
        </div>
        <div className="form-button-container">
          <ButtonGroup
            disabled={!isValid}
            secondaryOnClick={onBack}
            fromReview={state?.fromReview}
            onBackToReview={onBackToReview}
          />
        </div>
      </form>
    </div>
  );
};

export default Product;
