import { FC, useState } from "react";
import "../styles.scss";
import { Modal as CarbonModal, Link } from "carbon-components-react";

interface NavigateModalProps {
  setOpen: Function;
  open: boolean;
}

const NavigateModal: FC<NavigateModalProps> = ({ setOpen, open }) => {
  return (
    <CarbonModal
      modalHeading={"Are you sure you want to navigate away?"}
      primaryButtonText={"Yes, Leave Page"}
      secondaryButtonText={"No, Stay Here"}
      open={open}
      size={"xs"}
      onRequestSubmit={() => {
        setOpen(false);
      }}
      onRequestClose={() => setOpen(false)}
    >
      <div className="modal-content">
        Once you leave the page, all entered data that has not already been
        submitted will be lost.
      </div>
    </CarbonModal>
  );
};

export default NavigateModal;
