import { FC, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { Dropdown as CarbonDropdown } from "carbon-components-react";

interface DropdownProps {
  label: string;
  name: string;
  items: ({} | string)[];
  control: any;
  Controller: any;
  toggleValue?: boolean;
  error?: string;
  register?: any;
  size?: string;
  placeholder?: string;
  disabled?: boolean;
  setValue?: Function;
  getValues?: Function;
  watch?: Function;
  defaultItem?: any;
  QuestionTooltip?: any;
  className?: string;
}

const Dropdown: FC<DropdownProps> = (props) => {
  const {
    name,
    label,
    error,
    disabled,
    items,
    defaultItem,
    control,
    Controller,
    setValue = () => {},
    getValues = () => {},
    watch = () => {},
    toggleValue,
    register,
    placeholder = "Select...",
    size = "large",
    QuestionTooltip,
    className,
  } = props;

  const selectedLabelName = name.concat("Label");
  const fieldToWatch = watch(name); // see comments in text input component
  const [dropdownPreviouslyClicked, setDropdownPreviouslyClicked] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (toggleValue) {
      // this setValue is what triggers the watch above
      setValue(name, 'unknown', { shouldValidate: true });
      setValue(selectedLabelName, 'unknown');
      resetDropdownIndicators();
    } else {
      setValue(name, placeholder, { shouldValidate: false });
      setValue(selectedLabelName, defaultItem ? defaultItem.label: placeholder);
      resetDropdownIndicators();
    }
  }, [toggleValue]);

  const resetDropdownIndicators = () => {
    setDropdownPreviouslyClicked(false);
  }

  useEffect(() => {
    if (defaultItem) {
      setValue(name, defaultItem.value || defaultItem, {
        shouldValidate: true,
      });
    }
  }, []);

  useEffect(() => {
    if (register) {
      register(selectedLabelName, defaultItem ? defaultItem.label : fieldToWatch);
    }
  }, [register])

  useEffect(() => {
    function handleClickOutside(event) {
      if (parentDoesNotContainsEventTarget(event)) {
        dropdownRequiredValidation();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  function parentDoesNotContainsEventTarget(event) {
    const element = divRef.current;
    if (element) {
      return !element.contains(event.target);
    }
    return false;
  }

  const handleDropdownToggling = () => {
    dropdownRequiredValidation();
    setDropdownPreviouslyClicked(true);
  };

  let onBlurMethod;

  const dropdownRequiredValidation = () => {
    if (dropdownPreviouslyClicked && onBlurMethod) {
      onBlurMethod();
    }
  };

  return (
    <div className={`${size} ${className}`} ref={divRef}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultItem?.value}
        render={({ field: { onChange, onBlur } }) => (
          <CarbonDropdown
            name={name}
            id={name}
            titleText={
              <>
                {label}
                {QuestionTooltip}
              </>
            }
            selected={fieldToWatch}
            label={getValues(selectedLabelName)}
            disabled={disabled || toggleValue}
            items={items || []}
            itemToString={(item) => (item ? item.label : "")}
            initialSelectedItem={defaultItem}
            invalid={!!error}
            invalidText={error}
            onChange={({ selectedItem  }) => {
              onChange(selectedItem.value);
              setValue(selectedLabelName, selectedItem.label);
            }}
            onBlur={() => {
              if (!onBlurMethod) {
                onBlurMethod = onBlur;
              }
            }}
            onMouseDown={handleDropdownToggling}
          />
        )}
      />
    </div>
  );


};



export default Dropdown;
