import { FC, useState } from "react";
import { Tooltip as CarbonTooltip } from "carbon-components-react";
import "./styles.scss";

interface TooltipProps {
  message: string;
  direction: string;
  trigger: any;
}

const Tooltip: FC<TooltipProps> = (props) => {
  const { message, direction, trigger } = props;

  const iconProps = {
    align: "center",
    direction: direction,
    tabIndex: 0,
    selectorPrimaryFocus: "",
    showIcon: false,
  };
  const [tooltipVisible, setTooltipVisible] = useState(false);
  return (
    <div className="tooltip-form">
      <CarbonTooltip
        {...{
          ...iconProps,
          focusTrap: false,
        }}
        triggerText={
          <div
            onMouseOver={() => setTooltipVisible(true)}
            onMouseOut={() => setTooltipVisible(false)}
          >
            {trigger}
          </div>
        }
        open={tooltipVisible}
      >
        <p id="tooltip-body">{message}</p>
      </CarbonTooltip>
    </div>
  );
};

export default Tooltip;
