import { getToken } from "./auth";
import { formatCase } from "./data";

// sets the window object apiConfig,
// which contains URLs for API calls
// only needs to be called in index.tsx
// see index.tsx for window interface
export function setupEndpoints(callback) {
  fetch("/config.json")
    .then((res) => res.json())
    .then(({ config }) => {
      window.apiConfig = config;
    })
    .then(callback)
    .catch(() => console.error("no config.json"));
}

// calls CMS to get lists of dropdown options
// that Trilogy uses
export function getDropdownOptions(_affiliate) {
  const fetchDropdownOptions = (path) => {
    callCMS(path)
      .then((res) => res.json())
      .then(
        (json) =>
          (window.dropdownOptions = {
            ...window.dropdownOptions,
            ...json["documentDataOptionsGroup"],
          })
      );
  };

  const fetchDocumentDataList = (path, dropdownName) => {
    callCMS(path)
        .then((res) => res.json())
        .then(
            (json) =>
              (window.dropdownOptions = {
                ...window.dropdownOptions,
                [dropdownName]: json["documentDataList"],
              })
        );
  }

  const callCMS = (path) => {
    return fetch(
        `${window.apiConfig.core.uri}/cms/affiliate/_DEFAULT/document-data/${path}`,
        {
          headers: {
            "x-api-key": window.apiConfig.core.key,
            Authorization: `Bearer ${getToken()}`,
          },
        }
    )
  }

  fetchDropdownOptions("pq-options");
  fetchDropdownOptions("ae-options");
  fetchDropdownOptions("mastercase-options");
  fetchDocumentDataList("country-options", "country_options");
  return true;
}

// graphql call to case-svc
export function createCase(formData) {
  const apiConfig = window.apiConfig.regionalConfigs.find(
    (config) => config.affiliateCode === formData.affiliate
  );
  var query = `mutation CreateCaseAndId($case: caseInput!) {
    createCaseAndId(case: $case) {
      id
    }
  }`;

  return fetch(`${apiConfig?.uri}/case/graphql/sharp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-api-key": apiConfig?.key || "",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify({
      query,
      variables: {
        case: formatCase(formData),
      },
    }),
  })
    .then((r) => r.json())
    .then((response) => response?.data?.createCaseAndId?.id);
}
