import { FC } from "react";
import "../styles.scss";
import { Header, Button } from "../../../components";
import { getAuth } from "../../../utilities/auth";
import { Error32 } from "@carbon/icons-react";
import { useHistory } from "react-router-dom";

interface ErrorProps {}

const Error: FC<ErrorProps> = () => {
  let history = useHistory();
  return (
    <div>
      <Header />
      <div className="error-container">
        <div className="error-icon">
          <Error32 />
        </div>
        <div className="error-content">
          <div>We've run into an error.</div>
          <div className="error-text">Please try again later.</div>
          <Button label="Reload Page" onClick={() => history.push("/")} />
        </div>
      </div>
    </div>
  );
};

export default Error;
