import { FC, useState } from "react";
import { Loading } from "carbon-components-react";
import ReactDOM from "react-dom";
import "../styles.scss";

interface ModalStateManagerProps {
  renderLauncher: any;
  children: any;
  caseId?: string;
}

const ModalStateManager: FC<ModalStateManagerProps> = ({
  renderLauncher: LauncherContent,
  children: ModalContent,
  caseId,
}) => {
  const [open, setOpen] = useState(false);

  // Used for SubmitModal
  const [hasCopied, setHasCopied] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      {!ModalContent || typeof document === "undefined"
        ? null
        : ReactDOM.createPortal(
            <ModalContent
              open={open}
              setOpen={setOpen}
              caseId={caseId}
              setHasCopied={setHasCopied}
              hasCopied={hasCopied}
            />,
            document.body
          )}
      {LauncherContent && (
        <LauncherContent
          open={open}
          setOpen={setOpen}
          setLoading={setLoading}
        />
      )}
      <Loading
        active={loading}
        withOverlay={true}
        description={"Please wait a moment..."}
      />
    </>
  );
};

export default ModalStateManager;
