import {getOptionData} from "../../utilities/helpers";
import {Dropdown} from "../../components";

export const batchLotNumberUnavailableField = (formData, reactFormProps, errors, whyBatchNumUnavailableClosedPlaceholder) => (
    <Dropdown
        label="Why is the batch/lot number unavailable? *"
        name="batchNumberUnavailable"
        items={
            window.dropdownOptions?.sharp_lot_number_unavailable_reason
        }
        {...reactFormProps}
        size="extra-large"
        defaultItem={getOptionData(
            window.dropdownOptions?.sharp_lot_number_unavailable_reason,
            formData["batchNumberUnavailable"]
        )}
        error={errors.batchNumberUnavailable?.message}
        placeholder={whyBatchNumUnavailableClosedPlaceholder}
    />
)