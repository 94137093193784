import { FC, useState } from "react";
import "./styles.scss";
import { Button, ButtonGroup, SubmitModal } from "../../components";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionItem } from "carbon-components-react";
import { Edit32 } from "@carbon/icons-react";
import moment from "moment";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../hooks/useWindowSize";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

interface ReviewProps {
  goNextSection: Function;
  formData: Object;
  clearState: Function;
}

const Review: FC<ReviewProps> = ({ goNextSection, formData, clearState }) => {
  let history = useHistory();

  const { width } = useWindowSize();

  const [nextProgressIndex, setNextProgressIndex] = useState(1);
  const [_nextSection, setNextSection] = useState("/reporter");
  const [isSectionOpen, setIsSectionOpen] = useState({
    formSetup: true,
    issueType: true,
    reporterInformation: true,
    patientInformation: true,
    productInformation: true,
    productQualityComplaintInformation: true,
    adverseEventInformation: true,
  });

  const onBack = () => {
    setNextProgressIndex(-1);
    setNextSection("/");
  };

  const renderEditButton = (sectionIndex, page) => (
    <Button
      label={width <= MOBILE_MAX ? "" : "Edit Responses"}
      hasIconOnly={width <= MOBILE_MAX}
      kind="secondary"
      onClick={() => {
        goNextSection(formData, sectionIndex);
        history.push({
          pathname: page,
          state: { fromReview: true },
        });
      }}
      renderIcon={Edit32}
      cypressSelector={"edit-btn"}
    />
  );

  const displayDate = (field) => {
    const type = formData[`${field}Type`];
    const date = formData[field];
    if (formData[field] === "unknown") {
      return "unknown";
    } else if (formData[field]) {
      if (type === "month") {
        return moment(date).format("MMM YYYY");
      } else if (type === "year") {
        return moment(date).format("YYYY");
      } else return moment(formData[field]).format("DD MMM YYYY");
    } else return null;
  };

  const getYesOrNo = (field) => {
    return formData[field] ? "Yes" : "No";
  };

  return (
    <div className={`tab-container`}>
      <div className="tab-title">Review your answers before submitting</div>
      <div className={`form-sections-container ${width <= MOBILE_MAX && "review-section-mobile"}`}>
        <Accordion className="accordion">
          <AccordionItem
            onHeadingClick={(event) =>
              setIsSectionOpen((prevState) => {
                return {
                  ...prevState,
                  employeeInfo: event.isOpen,
                };
              })
            }
            title={

              <div className="review-section-title" data-cy="employee-info-title">

                Employee Information
                {isSectionOpen["employeeInfo"]}
              </div>
            }
            open={true}
          >
            <div className="field-container">
              <div className="field-name">Name</div>
              <div>{formData["user"]}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Email</div>
              <div>{formData["email"]}</div>
            </div>
          </AccordionItem>
          <AccordionItem
            onHeadingClick={(event) =>
              setIsSectionOpen((prevState) => {
                return {
                  ...prevState,
                  formSetup: event.isOpen,
                };
              })
            }
            title={

              <div className="review-section-title" data-cy="landing-title">

                Language and Location
                {isSectionOpen["formSetup"] && renderEditButton(-1, "/landing")}
              </div>
            }
            open={true}
          >
            <div className="field-container">
              <div className="field-name">Language</div>
              <div>{formData["languageLabel"]}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Affiliate Location</div>
              <div>{formData["affiliateLabel"]}</div>
            </div>
          </AccordionItem>
          <AccordionItem
            onHeadingClick={(event) =>
              setIsSectionOpen((prevState) => {
                return {
                  ...prevState,
                  issueType: event.isOpen,
                };
              })
            }
            title={

              <div className="review-section-title" data-cy="issue-title">

                Issue Type
                {isSectionOpen["issueType"] && renderEditButton(0, "/issue")}
              </div>
            }
            open={true}
          >
            <div className="field-container">
              <div className="field-name">Is Adverse Event</div>
              <div>{getYesOrNo("isAE")}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Is Product Quality Complaint</div>
              <div>{getYesOrNo("isPQ")}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Awareness Date</div>
              <div>{displayDate("awarenessDate")}</div>
            </div>
          </AccordionItem>
          <AccordionItem
            onHeadingClick={(event) =>
              setIsSectionOpen((prevState) => {
                return {
                  ...prevState,
                  reporterInformation: event.isOpen,
                };
              })
            }
            title={

              <div className="review-section-title" data-cy="reporter-title">

                Reporter Information
                {isSectionOpen["reporterInformation"] &&
                  renderEditButton(1, "/reporter")}
              </div>
            }
            open={true}
          >
            <div className="field-container">
              <div className="field-name">Reported by Patient</div>
              <div>{formData["reportedByPatient"]}</div>
            </div>
            {formData["reportedByPatient"] === "No" && (
              <>
                <div className="field-container">
                  <div className="field-name">Reporter</div>
                  <div>
                    {formData["reporterFirstName"]}{" "}
                    {formData["reporterLastName"]}
                  </div>
                </div>
                <div className="field-container">
                  <div className="field-name">Relationship to Patient</div>
                  <div>{formData["reporterRelationship"]}</div>
                </div>
                <div className="field-container">
                  <div className="field-name">Reporter Occupation</div>
                  <div>{formData["reporterOccupationLabel"]}</div>
                </div>
              </>
            )}
            {formData["reportedByPatient"] === "Yes" && (
              <>
                <div className="field-container">
                  <div className="field-name">Patient</div>
                  <div>
                    {formData["patientFirstName"]} {formData["patientLastName"]}
                  </div>
                </div>
                <div className="field-container">
                  <div className="field-name">Reporter Occupation</div>
                  <div>{formData["patientOccupationLabel"]}</div>
                </div>
                <div className="field-container">
                  <div className="field-name">
                    Permission to Contact the Patient
                  </div>
                  <div>{formData["contactTheReporter"]}</div>
                </div>
              </>
            )}
            {formData["reportedByPatient"] === "No" && (
              <div className="field-container">
                <div className="field-name">
                  Permission to Contact the Reporter
                </div>
                <div>{formData["contactTheReporter"]}</div>
              </div>
            )}
            {formData["contactTheReporter"] === "Yes" && (
              <>
                <div className="field-container">
                  <div className="field-name">Email</div>
                  <div>{formData["reporterEmail"]}</div>
                </div>
                {formData["reporterEmail"] === "unknown" && (
                  <div className="field-container">
                    <div className="field-name">Phone</div>
                    <div>{formData["reporterPhone"]}</div>
                  </div>
                )}
                {formData["reporterPhone"] === "unknown" && (
                  <>
                    <div className="field-container">
                      <div className="field-name">Address Line 1</div>
                      <div>{formData["reporterAddressLine1"]}</div>
                    </div>
                    <div className="field-container">
                      <div className="field-name">Address Line 2</div>
                      <div>{formData["reporterAddressLine2"]}</div>
                    </div>
                    <div className="field-container">
                      <div className="field-name">City</div>
                      <div>{formData["reporterCity"]}</div>
                    </div>
                    <div className="field-container">
                      <div className="field-name">State</div>
                      <div>{formData["reporterState"]}</div>
                    </div>
                    <div className="field-container">
                      <div className="field-name">Zip Code</div>
                      <div>{formData["reporterZip"]}</div>
                    </div>
                    <div className="field-container">
                      <div className="field-name">Country</div>
                      <div>{formData["reporterCountryLabel"]}</div>
                    </div>
                  </>
                )}
              </>
            )}
          </AccordionItem>
          <AccordionItem
            onHeadingClick={(event) =>
              setIsSectionOpen((prevState) => {
                return {
                  ...prevState,
                  patientInformation: event.isOpen,
                };
              })
            }
            title={

              <div className="review-section-title" data-cy="patient-title">

                Patient Information
                {isSectionOpen["patientInformation"] &&
                  renderEditButton(2, "/patient")}
              </div>
            }
            open={true}
          >
            <div className="field-container">
              <div className="field-name">Patient Initials</div>
              <div>{formData["patientInitials"]}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Patient's Gender</div>
              <div>{formData["patientGenderLabel"]}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Patient's Date of Birth</div>
              <div>{displayDate("patientDob")}</div>
            </div>
            {formData["patientDob"] === "unknown" && (
              <div className="field-container">
                <div className="field-name">Patient's Age</div>
                <div>
                  {formData["patientAge"]} {formData["patientsAgeUnitsLabel"]}
                </div>
              </div>
            )}
            {formData["patientsAgeUnits"] === "unknown" && (
              <div className="field-container">
                <div className="field-name">Patient's Age Group</div>
                <div>{formData["patientAgeGroupLabel"]}</div>
              </div>
            )}
          </AccordionItem>
          <AccordionItem
            onHeadingClick={(event) =>
              setIsSectionOpen((prevState) => {
                return {
                  ...prevState,
                  productInformation: event.isOpen,
                };
              })
            }
            title={

              <div className="review-section-title" data-cy="product-title">

                Product Information
                {isSectionOpen["productInformation"] &&
                  renderEditButton(3, "/product")}
              </div>
            }
            open={true}
          >
            <div className="field-container">
              <div className="field-name">Product Name</div>
              <div>{formData["productName"]}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Batch/Lot Number</div>
              <div>{formData["batchNumber"]}</div>
            </div>
            {formData["batchNumber"] === "unknown" && (
              <div className="field-container">
                <div className="field-name">Reason Unavailable</div>
                <div>{formData["batchNumberUnavailableLabel"]}</div>
              </div>
            )}
            <div className="field-container">
              <div className="field-name">Patient's Dosage</div>
              {formData["patientDosage"] &&
                formData["patientDosage"] !== "unknown" && (
                  <div>
                    {formData["patientDosage"]} {formData["dosageUnitsLabel"]}
                  </div>
                )}
              {formData["patientDosage"] === "unknown" && (
                <div>{formData["patientDosage"]}</div>
              )}
            </div>
            <div className="field-container">
              <div className="field-name">Form of Administration</div>
              <div>{formData["formOfAdministrationLabel"]}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Condition</div>
              <div>{formData["patientConditions"]}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Patient start date</div>
              <div>{displayDate("productDate")}</div>
            </div>
          </AccordionItem>
          <AccordionItem
            onHeadingClick={(event) =>
              setIsSectionOpen((prevState) => {
                return { ...prevState, adverseEventInformation: event.isOpen };
              })
            }
            title={

              <div className="review-section-title" data-cy="ae-title">

                Adverse Event Information
                {isSectionOpen["adverseEventInformation"] &&
                  renderEditButton(4, "/adverseEvent")}
              </div>
            }
            open={true}
          >
            <div className="field-container">
              <div className="field-name">Adverse Event</div>
              <div>{formData["adverseEvent"]}</div>
            </div>
            <div className="field-container">
              <div className="field-name">First experience date</div>
              <div>{displayDate("firstEventDate")}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Last experience date</div>
              <div>{displayDate("lastEventDate")}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Outcome</div>
              <div>
                {formData["outcomeLabel"] !== "Select..." &&
                  formData["outcomeLabel"]}
              </div>
            </div>
            <div className="field-container">
              <div className="field-name">Additional Information</div>
              <div>{formData["additionalInformation"]}</div>
            </div>
          </AccordionItem>
          <AccordionItem
            onHeadingClick={(event) =>
              setIsSectionOpen((prevState) => {
                return {
                  ...prevState,
                  productQualityComplaintInformation: event.isOpen,
                };
              })
            }
            title={

              <div className="review-section-title" data-cy="pq-title">

                Product Quality Complaint Information
                {isSectionOpen["productQualityComplaintInformation"] &&
                  renderEditButton(5, "/qualityassurance")}
              </div>
            }
            open={true}
          >
            <div className="field-container">
              <div className="field-name">Product Quality Complaint</div>
              <div>{formData["qualityAssuranceDescription"]}</div>
            </div>
            <div className="field-container">
              <div className="field-name">Sample Available for Return</div>
              <div>
                {formData["sampleAvailableForReturnDropdownLabel"] !==
                  "Select..." &&
                  formData["sampleAvailableForReturnDropdownLabel"]}
              </div>
            </div>
            {formData["sampleAvailableForReturnDropdown"] === "no" && (
              <div className="field-container">
                <div className="field-name">Sample not available reason</div>
                <div>
                  {formData[
                    "sampleNotAvailableForReturnReasonDropdownLabel"
                  ] !== "Select..." &&
                    formData["sampleNotAvailableForReturnReasonDropdownLabel"]}
                </div>
              </div>
            )}
            {formData["sampleNotAvailableForReturnReasonDropdown"] ===
              "other" && (
              <div className="field-container">
                <div className="field-name">
                  Sample not available description
                </div>
                <div>
                  {formData["sampleNotAvailableForReturnReasonTextArea"]}
                </div>
              </div>
            )}
            <div className="field-container">
              <div className="field-name">Replacement Requested</div>
              <div>{formData["patientRequestReplacementRadio"]}</div>
            </div>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="form-button-container confirm-button">
        <ButtonGroup
          disabled={false}
          secondaryOnClick={onBack}
          secondaryLabel="Back to Form"
          primaryLabel="Confirm and Submit"
          modal={SubmitModal}
          formData={formData}
          clearState={clearState}
        />
      </div>
    </div>
  );
};

export default Review;
