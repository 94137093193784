import { FC } from "react";
import "./styles.scss";
import { Button as CarbonButton } from "carbon-components-react";

interface ButtonProps {
  onClick?: Function;
  label?: string;
  type?: string;
  kind?: string;
  renderIcon?: string | boolean;
  disabled?: boolean;
  hasIconOnly?: boolean;

  cypressSelector?: string;
}

const Button: FC<ButtonProps> = (props) => {
  const { onClick, label, kind, disabled, renderIcon, type = "button", hasIconOnly=false, cypressSelector } = props;


  return (
    <CarbonButton
      title="button"
      id="button"
      kind={kind}
      onClick={onClick}
      renderIcon={renderIcon}
      disabled={disabled}
      type={type}
      hasIconOnly={hasIconOnly}

      data-cy={cypressSelector}

    >
      {label}
    </CarbonButton>
  );
};

export default Button;
