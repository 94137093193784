import {Dropdown, TextArea, UnknownToggle} from "../../components";
import {getOptionData} from "../../utilities/helpers";

export const sampleNotAvailableReasonFields = (formData, reactFormProps, errors,
                                               sampleNotAvailableForReturnReasonPlaceholder, isPQ) => (
    <UnknownToggle
        id="sampleAvailableForReturnReasonUnknownToggle"
        defaultToggled={
            formData["sampleNotAvailableForReturnReasonDropdown"] ===
            "unknown"
        }
    >
        <Dropdown
            {...reactFormProps}
            label={`Why is the sample not available for return? ${
                isPQ ? " *" : ""
            }`}
            name="sampleNotAvailableForReturnReasonDropdown"
            items={window.dropdownOptions?.sample_unavailable_reason}
            defaultItem={getOptionData(
                window.dropdownOptions?.sample_unavailable_reason,
                formData["sampleNotAvailableForReturnReasonDropdown"]
            )}
            error={
                errors.sampleNotAvailableForReturnReasonDropdown?.message
            }
            placeholder={sampleNotAvailableForReturnReasonPlaceholder}
        />
    </UnknownToggle>
)

export const sampleNotAvailableReturnTextFields = (formData, reactFormProps, isPQ, errors) => (
    <div className="textbox-row">
        <TextArea
            {...reactFormProps}
            name="sampleNotAvailableForReturnReasonTextArea"
            label={`Describe why the sample is not available for return. ${
                isPQ ? " *" : ""
            }`}
            size="extra-large"
            placeholderText="Type description here"
            error={
                errors.whySampleNotAvailableForReturnTextArea?.message
            }
            defaultValue={
                formData["sampleNotAvailableForReturnReasonTextArea"]
            }
        />
    </div>
)