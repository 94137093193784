import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { getAuth } from "./utilities/auth";
import { setupEndpoints } from "./utilities/api";
// import { PreventNavigate } from "./components";

// see config-svc for template
// and definitions for different
// environments
declare global {
  interface Window {
    shouldSignInPopup: boolean;
    shouldPreventNavigate: boolean;
    apiConfig: {
      // used for auth-svc/authentication
      issuer: string;

      // CMS (dropdown options)
      core: {
        uri: string;
        key: string;
      };

      // will call different case-svc
      // endpoints based on affiliate
      // user has selected
      regionalConfigs: Array<RegionalConfig>;
    };
    dropdownOptions: any;
  }
}
interface RegionalConfig {
  affiliateCode: string;
  affiliateCountry: string;
  region: string;
  uri: string;
  key: string;
}

window.shouldSignInPopup = true;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

setupEndpoints(
  () =>
    getAuth() &&
    ReactDOM.render(
      <React.StrictMode>
        <Router>
          <ScrollToTop />
          <App />
        </Router>
      </React.StrictMode>,
      document.getElementById("root")
    )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
