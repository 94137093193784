import {FC, useEffect, useRef, useState} from "react";
import "./styles.scss";
import {
    ButtonGroup,
    DatePicker,
    TextInput,
    Dropdown,
    UnknownToggle,
} from "../../components";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useHistory, useLocation} from "react-router-dom";
import * as yup from "yup";
import {getOptionData, getDefaultDate} from "../../utilities/helpers";
import moment from "moment";
import {patientAgeFields, patientAgeGroupFields} from "./helper";
import {resetFields} from "../index";

interface PatientProps {
    goNextSection: Function;
    formData: object;
}

interface stateType {
    fromReview: boolean;
}

const Patient: FC<PatientProps> = ({goNextSection, formData}) => {
    const patientGenderPlaceholder = "Select...";
    const patientAgeUnitsPlaceholder = "Select...";
    const patientAgeGroupPlaceholder = "Select...";

    const [shouldValidate, setShouldValidate] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const schema = shouldValidate
        ? yup.object().shape({
            patientInitials: yup
                .string()
                .required("Required")
                .matches(/^[a-zA-Z]*$/, "Please only enter letters")
                .max(360, "The maximum number of characters has been reached"),
            patientGender: yup.string().required("Required").notOneOf([patientGenderPlaceholder], "Required"),
            patientDob: yup.string().required("Required"),
            patientAge: yup.string().when(["patientDob"], {
                is: "unknown",
                then: yup.string().required("Required"),
                otherwise: yup.string().nullable()
            }),
            patientsAgeUnits: yup.string().when("patientDob", {
                is: "unknown",
                then: yup.string().required("Required").notOneOf([patientAgeUnitsPlaceholder], "Required"),
                otherwise: yup.string().nullable()
            }),
            patientAgeGroup: yup.string().when(["patientDob", "patientAge"], {
                is: (patientDob, patientAge) => patientDob === "unknown" && patientAge === "unknown",
                then: yup.string().required("Required").notOneOf([patientAgeGroupPlaceholder], "Required"),
                otherwise: yup.string().nullable()
            }),
        })
        : yup.object().shape({});

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
        watch,
        resetField,
        formState: {errors, isValid},
        register,
        unregister
    } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
    });

    const patientDobValue = watch("patientDob");
    const patientAgeValue = watch("patientAge");

    let history = useHistory();

    const [nextProgressIndex, setNextProgressIndex] = useState(3);
    const [nextSection, setNextSection] = useState("/product");

    const onBack = () => {
        setNextProgressIndex(1);
        setNextSection("/reporter");
        setShouldValidate(false);
    };

    const onSubmit = (data) => {
        goNextSection(data, nextProgressIndex);
        history.push(nextSection);
    };

    const {state} = useLocation<stateType>();

    const onBackToReview = () => {
        setNextProgressIndex(6);
        setNextSection("/review");
    };

    const reactFormProps = {
        control,
        Controller,
        setValue,
        getValues,
        watch,
        resetField,
        register,
        unregister
    };

    let patientAge;
    if (patientDobValue === "unknown") {
        patientAge = patientAgeFields(formData, reactFormProps, patientAgeUnitsPlaceholder, errors);
    }

    let patientAgeGroup;
    if (patientDobValue === "unknown" && patientAgeValue === "unknown") {
        patientAgeGroup = patientAgeGroupFields(formData, reactFormProps, patientAgeGroupPlaceholder, errors);
    }

    useEffect(() => setIsLoading(false), []);

    useEffect(() => {
        if (!isLoading && !patientAge) {
            resetFields(
                [
                    "patientAge",
                    "patientsAgeUnits",
                    "patientsAgeUnitsLabel",
                    "patientAgeGroup",
                    "patientAgeGroupLabel"
                ],
                unregister, formData, resetField
            )
        }
    }, [patientAge])

    useEffect(() => {
        if (!isLoading && !patientAgeGroup) {
            resetFields(
                [
                    "patientAgeGroup",
                    "patientAgeGroupLabel"
                ],
                unregister, formData, resetField
            )
        }
    }, [patientAgeGroup])
        
    useEffect(() => {
        getDefaultDate("patientDob", formData["patientDob"], setValue);
    }, []);

    return (
        <div className="tab-container">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="tab-title">Patient Information</div>
                <div className="form-sections-container">
                    <div className="form-section">
                        <div className="first-child-padding"></div>
                        <UnknownToggle
                            id={"patientInitialsToggle"}
                            defaultToggled={formData["patientInitials"] === "unknown"}
                        >
                            <TextInput
                                {...reactFormProps}
                                name="patientInitials"
                                label="Patient Initials *"
                                placeholderText={"Patient Initials"}
                                error={errors.patientInitials?.message}
                                size={"large"}
                                defaultValue={formData["patientInitials"]}
                            />
                        </UnknownToggle>
                        <UnknownToggle
                            id={"patientGenderToggle"}
                            defaultToggled={formData["patientGender"] === "unknown"}
                        >
                            <Dropdown
                                {...reactFormProps}
                                name="patientGender"
                                label="Patient's Gender *"
                                placeholder={patientGenderPlaceholder}
                                items={window.dropdownOptions?.gender}
                                error={errors.patientGender?.message}
                                size={"large"}
                                defaultItem={getOptionData(
                                    window.dropdownOptions?.gender,
                                    formData["patientGender"]
                                )}
                            />
                        </UnknownToggle>
                    </div>
                    <div className="form-section">
                        <div className="first-child-padding"></div>
                        <UnknownToggle
                            id="patientDobToggle"
                            defaultToggled={formData["patientDob"] === "unknown"}
                        >
                            <DatePicker
                                {...reactFormProps}
                                name="patientDob"
                                error={errors.patientDob?.message}
                                label="Patient's Date of Birth *"
                                size="large"
                                defaultValue={
                                    formData["patientDob"] ? moment(formData["patientDob"]) : null
                                }
                                defaultType={formData["patientDobType"]}
                            />
                        </UnknownToggle>
                        <div className="first-child-padding"></div>
                        {patientAge}
                        {patientAgeGroup}
                    </div>
                </div>
                <div className="form-button-container">
                    <ButtonGroup
                        disabled={!isValid}
                        secondaryOnClick={onBack}
                        fromReview={state?.fromReview}
                        onBackToReview={onBackToReview}
                    />
                </div>
            </form>
        </div>
    );
};

export default Patient;
