import { FC, useState, useEffect } from "react";
import "./styles.scss";
import { Checkbox, DatePicker, ButtonGroup } from "../../components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { getOptionData, getDefaultDate } from "../../utilities/helpers";
import useWindowSize, {
  deviceWidthBreakpoints
} from "../../hooks/useWindowSize";

const { MOBILE_MAX, TABLET_MAX } = deviceWidthBreakpoints;

interface IssueProps {
  goNextSection: Function;
  formData: object;
}

interface stateType {
  fromReview: boolean;
}

const Issue: FC<IssueProps> = ({ goNextSection, formData }) => {
  const { width } = useWindowSize();
  const [shouldValidate, setShouldValidate] = useState(true);

  const schema = shouldValidate
    ? yup.object().shape(
        {
          isAE: yup.boolean().when("isPQ", {
            is: (isPQ) => !isPQ,
            then: yup.boolean().required("Required"),
            otherwise: yup.boolean(),
          }),
          isPQ: yup.boolean().when("isAE", {
            is: (isAE) => !isAE,
            then: yup.boolean().required("Required"),
            otherwise: yup.boolean(),
          }),
          awarenessDate: yup.string().required("Required"),
        },
        [["isAE", "isPQ"]]
      )
    : yup.object().shape({});

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    resetField,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  let history = useHistory();

  const [nextProgressIndex, setNextProgressIndex] = useState(1);
  const [nextSection, setNextSection] = useState("/reporter");

  const onBack = () => {
    setShouldValidate(false);
    setNextProgressIndex(-1);
    setNextSection("/");
  };

  const onSubmit = (data) => {
    goNextSection(data, nextProgressIndex);
    history.push(nextSection);
  };

  const { state } = useLocation<stateType>();
  const onBackToReview = () => {
    setNextProgressIndex(6);
    setNextSection("/review");
  };

  const reactFormProps = {
    control,
    Controller,
    setValue,
    watch,
    resetField,
  };

  const isAE = watch("isAE");
  const isPQ = watch("isPQ");

  useEffect(() => {
    getDefaultDate("awarenessDate", formData["awarenessDate"], setValue);
  }, []);

  return (
    <div className="tab-container">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="tab-title">Issue Type</div>
        <div className="form-sections-container">
          <div className={`form-section ${width > MOBILE_MAX && "form-section-horizontal"}`}>
            <fieldset>
              <legend>What would you like to document this issue as? *</legend>
              <Checkbox
                {...reactFormProps}
                name="isAE"
                label="Adverse Event"
                defaultChecked={formData["isAE"]}
              ></Checkbox>
              <Checkbox
                {...reactFormProps}
                name="isPQ"
                label="Product Quality Complaint"
                defaultChecked={formData["isPQ"]}
              ></Checkbox>
            </fieldset>
          </div>
          <div className="form-section">
            <DatePicker
              {...reactFormProps}
              name="awarenessDate"
              error={errors.awarenessDate?.message}
              label="When were you first notified of this issue? *"
              defaultValue={moment(formData["awarenessDate"])}
              defaultType={formData["awarenessDateType"]}
            />
          </div>
        </div>
        <div className="form-button-container">
          <ButtonGroup
            disabled={
              !isValid ||
              // this is needed because yup is validating false checkbox values as truthy
              !(isAE || isPQ)
            }
            secondaryOnClick={onBack}
            fromReview={state?.fromReview}
            onBackToReview={onBackToReview}
          />
        </div>
      </form>
    </div>
  );
};

export default Issue;
