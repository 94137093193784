import { FC, useEffect } from "react";
import "./styles.scss";
import { TextInput as CarbonTextInput } from "carbon-components-react";

interface TextInputProps {
  label: string;
  name: string;
  control: any;
  Controller: any;
  setValue?: Function;
  watch?: Function;
  error?: string;
  size?: string;
  placeholderText?: string;
  helperText?: string;
  disabled?: boolean;
  toggleValue?: boolean;
  QuestionTooltip?: any;
  resetField?: any;
  defaultValue?: string;
}

const TextInput: FC<TextInputProps> = (props) => {
  const {
    name,
    label,
    error,
    disabled,
    control,
    Controller,
    setValue = () => {},
    watch = () => {},
    toggleValue,
    placeholderText,
    helperText = false,
    size = "large",
    QuestionTooltip,
    resetField,
    defaultValue,
  } = props;

  // watches for any changes to field and get the value
  const fieldToWatch = watch(name);

  useEffect(() => {
    if (toggleValue) {
      // this setValue is what triggers the watch above
      setValue(name, "unknown", { shouldValidate: true });
    } else if (!toggleValue && resetField) {
      resetField(name);
      setValue(name, "");
    } else if (!toggleValue) {
      setValue(name, "");
    }
  }, [toggleValue]);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue, {
        shouldValidate: true,
      });
    }
  }, []);

  return (
    <div className={size}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur } }) => (
          <CarbonTextInput
            id={name}
            labelText={
              <>
                {label}
                {QuestionTooltip}
              </>
            }
            value={fieldToWatch}
            // set the watched field value ('unknown') as the placeholder instead of directly changing the value
            // since we are using uncontrolled components
            placeholder={fieldToWatch || placeholderText}
            helperText={helperText}
            disabled={disabled || toggleValue}
            invalid={!!error}
            invalidText={error}
            onChange={(value) => {
              onChange(value);
            }}
            onBlur={onBlur}
            defaultValue={defaultValue}
          />
        )}
      />
    </div>
  );
};

export default TextInput;
