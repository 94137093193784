import { FC } from "react";
import "./styles.scss";
import { Button } from "../../../../components";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";

interface ButtonGroupProps {
  disabled?: boolean;
  primaryLabel?: string;
  secondaryLabel?: string;
  secondaryOnClick: Function;
  fromReview?: boolean;
  onBackToReview?: Function;
  modal?: any;
  formData?: object;
  clearState?: Function;
}

const ButtonGroup: FC<ButtonGroupProps> = (props) => {
  const {
    disabled,
    primaryLabel = "Next",
    secondaryLabel = "Previous",
    secondaryOnClick,
    fromReview,
    onBackToReview,
    modal: Modal,
    formData,
    clearState,
  } = props;

  return (
    <div className="button-group-container">
      {!fromReview && window.location.pathname !== "/review" && (
        <div className="first-button-container">
          <Button
            label={secondaryLabel}
            type="submit"
            kind="tertiary"
            onClick={secondaryOnClick}
            renderIcon={ArrowLeft32}
          />
        </div>
      )}
      {!fromReview && (
        <div className="second-button-container">
          {Modal ? (
            <Modal formData={formData} clearState={clearState} />
          ) : (
            <Button
              label={primaryLabel}
              type="submit"
              disabled={disabled}
              kind={fromReview ? "tertiary" : "primary"}
              renderIcon={ArrowRight32}
            />
          )}
        </div>
      )}
      {fromReview && (
        <div className="back-to-review-button">
          <Button
            label="Back to Review"
            disabled={disabled}
            kind="primary"
            type="submit"
            onClick={onBackToReview}
          />
        </div>
      )}
    </div>
  );
};

export default ButtonGroup;
