import moment from "moment";

// Helper Functions
// TODO - Move more functions here!


export const getOptionData = (options, fieldValue) => {
  if (options) {
    return options[options.findIndex((x) => x.value === fieldValue)];
  }
};

export const getDefaultDate = (fieldName, fieldValue, setValue) => {
  if (fieldValue === "unknown") {
    setValue(fieldName, fieldValue, { shouldValidate: true });
  } else if (fieldValue) {
    setValue(fieldName, moment(fieldValue), {
      shouldValidate: true,
    });
  }
}