import { FC } from "react";
import "../styles.scss";
import { Header, Button } from "../../../components";
import { getAuth } from "../../../utilities/auth";
import { Logout32 } from "@carbon/icons-react";

interface LogoutProps {
  setShouldPreventNavigate: Function;
}

const Logout: FC<LogoutProps> = ({ setShouldPreventNavigate }) => {
  return (
    <div>
      <Header />
      <div className="error-container">
        <Logout32 />
        <div className="error-content">
          <div>You have been logged out.</div>
          <Button
            label="Log Back In"
            onClick={() => {
              setShouldPreventNavigate(false);
              getAuth();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Logout;
